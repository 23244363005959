import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Chip, Paper, Skeleton} from "@mui/material";
import UserContext from "../../../context/userContext";
import apiCall from "../../../api/axios_config";
import DeliveryStatus from "../../manager/orders/order_table/components/delivery_status";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";


export default function OrderReturn(props) {
    const {store} = props;
    const [returnList, setReturnList] = useState({});
    const [loading, setLoading] = useState(true);
    const {accessToken} = useContext(UserContext);

    function handleCollect(id){

        apiCall(accessToken).post('/stores/returns/collect',{
            id : id,
            store_id : store
        }).then((res) => setReturnList(res.data.orders))
            .catch((e) => {})
    }

    function handleReturn(id){
        apiCall(accessToken).post('/stores/returns/retour',{
            id : id,
            store_id : store
        }).then((res) => setReturnList(res.data.orders))
            .catch((e) => {})
    }
    useEffect(() => {
        apiCall(accessToken).get(`/stores/returns/${store}`)
            .then((r) => setReturnList(r.data.orders))
            .catch((e) => {
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Paper sx={{width: "100%", padding: 2}}>
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12} spacing={2}>
                    <Grid container item xs={12}>
                        <Typography fontWeight={600} variant={"h6"}>
                            Colis en retour :
                        </Typography>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Grid container item xs={12}>
                            <Paper elevation={8} sx={{width: "100%", zIndex: 2}}>
                                <Grid container columnSpacing={1} item
                                      sx={{
                                          height: "70px",
                                          padding: 2,
                                          fontWeight: 700,
                                          color: "white",
                                          backgroundColor: (t) => t.palette.primary.main,
                                          borderRadius : 2
                                      }}
                                      xs={12}
                                      alignItems="center"
                                >
                                    <Grid container item justifyContent="center" xs={1.5}>
                                        N°
                                    </Grid>
                                    <Divider orientation="vertical"/>
                                    <Grid container item justifyContent="center" xs={4}>
                                        Client
                                    </Grid>
                                    <Divider orientation="vertical"/>
                                    <Grid container item justifyContent="center" xs={3.5}>
                                        Livraison Infos
                                    </Grid>
                                    <Divider orientation="vertical"/>
                                    <Grid container item justifyContent="center" xs={2.8}>
                                        Actions
                                    </Grid>
                                </Grid>

                            </Paper>
                        </Grid>

                        <Grid container item xs={12} spacing={2} sx={{maxHeight: 850, overflow: "auto"}}>
                            {!loading ?
                                returnList.map((item) => (
                                    <Grid container item xs={12}>
                                        <Paper elevation={3} sx={{width: "100%"}}>
                                            <Grid container columnSpacing={1} item
                                                  sx={{height: "90px", padding: 2, fontWeight: 700}} xs={12}
                                                  alignItems="center">
                                                <Grid container item justifyContent="flex-start" xs={1.5}>
                                                    {item.order.commandeNumber}
                                                </Grid>
                                                <Divider orientation="vertical"/>
                                                <Grid container item justifyContent="flex-start" xs={4}>
                                                    <Typography noWrap>{item.order.client.fullName}</Typography>
                                                </Grid>
                                                <Divider orientation="vertical"/>
                                                <Grid container item justifyContent="center" xs={3.5}>
                                                    <DeliveryStatus order={item.order}/>
                                                </Grid>
                                                <Divider orientation="vertical"/>
                                                <Grid container item justifyContent="center" xs={2.8}>
                                                    <Stack spacing={1} direction={{ xs : "column" , sm : "row"}}>
                                                        <Chip
                                                            onClick={() => handleCollect(item.id)}
                                                            disabled={item.collected}
                                                            clickable
                                                            size="small"
                                                            color="success"
                                                            label="Collecté"
                                                            sx={{fontSize : 11}}
                                                        />
                                                        <Chip
                                                            onClick={() => handleReturn(item.id)}
                                                            disabled={item.returned}
                                                            clickable
                                                            size="small"
                                                            color="error"
                                                            label="retour"
                                                            sx={{fontSize : 11}}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                        </Paper>
                                    </Grid>

                                ))
                                :
                                [...Array(8)].map((item) => (
                                    <Grid container item xs={12}>
                                        <Paper elevation={3} sx={{width: "100%"}}>
                                            <Grid container columnSpacing={1} item
                                                  sx={{height: "90px", padding: 2, fontWeight: 700}} xs={12}
                                                  alignItems="center">
                                                <Grid container item justifyContent="flex-start" xs={1.5}>
                                                    <Skeleton variant="rectangular" sx={{width: "80%", height: 25, borderRadius: 10}}/>
                                                </Grid>
                                                <Divider orientation="vertical"/>
                                                <Grid container item justifyContent="flex-start" xs={4}>
                                                    <Skeleton variant="rectangular" sx={{width: "80%", height: 25, borderRadius: 10}}/>
                                                </Grid>
                                                <Divider orientation="vertical"/>
                                                <Grid container item justifyContent="center" xs={3.5}>
                                                    <Skeleton variant="rectangular" sx={{width: "80%", height: 25, borderRadius: 10}}/>
                                                </Grid>
                                                <Divider orientation="vertical"/>
                                                <Grid container item justifyContent="center" xs={2.8}>
                                                    <Skeleton variant="rectangular" sx={{width: "80%", height: 25, borderRadius: 10}}/>
                                                </Grid>
                                            </Grid>

                                        </Paper>
                                    </Grid>
                                ))

                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}