import React from "react";
import {Pagination} from "@mui/material";


export default function PaginationComponent({pagination,handleCurrentPage}){
    return(
        <Pagination
            color={"primary"}
            count={pagination.totalPage}
            page={pagination.currentPage}
            showFirstButton
            showLastButton
            onChange={(e,p) => {
                handleCurrentPage(p)
            }}
        />
    )
}