import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {getIn, useFormik} from "formik";
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import apiCall from "../../../api/axios_config";
import UserContext from "../../../context/userContext";
import AlertsContext from "../../../context/alertsContext";
import {useHistory} from "react-router-dom";

export default function EditClientModal({oldClient, govs, updateOldClient}) {
    const [open, setOpen] = React.useState(false);
    const [activeGov, setActiveGov] = useState({});
    const {accessToken} = useContext(UserContext);
    const {setSnackBar, setProgressBar} = useContext(AlertsContext);
    const history = useHistory();
    function filterSelectedGov(id) {
        formik.setFieldValue('deleg_id', "");
        const filtred = govs.find((gov) => gov.id === id);
        setActiveGov({
            gov: filtred.id, delegations: filtred.delegations
        })
    }

    useEffect(() => {
        if (govs) {
            const filtred = govs.find((gov) => gov.id === oldClient.delegation.gov.id);
            setActiveGov({
                gov: filtred.id, delegations: filtred.delegations
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [govs]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formik = useFormik({
        initialValues: {
            client_id: oldClient.id,
            fullName: oldClient.fullName,
            phone: oldClient.phone,
            phoneTwo: oldClient.phoneTwo,
            govId: oldClient.delegation.gov.id,
            deleg_id: oldClient.deleg_id,
            address: oldClient.address,
            mf: oldClient.mf
        }, onSubmit: (values) => {
            setProgressBar(true)
            apiCall(accessToken).post('/clients/update', values)
                .then((res) => {
                    updateOldClient(res.data.client);
                    setSnackBar({
                        status: true,
                        message: "Les informations sur le client ont été mises à jour avec succès",
                        severity: "success"
                    });
                    setProgressBar(false);
                    handleClose();
                }).catch((e) => {
                setSnackBar({
                        status: true,
                        message: "une erreur s'est produite lors de la tentative de mise à jour des informations du client",
                        severity: "error"
                    }
                )
                setProgressBar(false)
                handleClose();
            }).finally(() => {
                history.push('temp');
                history.goBack();

            })
        }
    })
    return (<div>
        <Button color="secondary" variant="contained" onClick={handleClickOpen}>
            Editer client
        </Button>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Editer informations client"}
            </DialogTitle>
            <DialogContent>
                <Grid container item xs={12} spacing={2}>
                    <Grid container item xs={12}>
                        <Grid container item xs={8}>
                            <Typography variant={"body1"}>
                                Informations client:
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} md={12} xs={12}>
                        <Grid container item md={6} xs={12}>
                            <TextField
                                fullWidth
                                required
                                name="fullName"
                                label="Nom et prénom"
                                variant="outlined"
                                value={formik.values.fullName}
                                onChange={formik.handleChange}
                                error={getIn(formik.touched, "fullName") && Boolean(getIn(formik.errors, "fullName"))}
                                helperText={getIn(formik.touched, "fullName") && getIn(formik.errors, "fullName")}
                            />
                        </Grid>
                        <Grid container item md={3} xs={12}>
                            <TextField
                                fullWidth
                                required
                                name="phone"
                                label="Télephone"
                                variant="outlined"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                error={getIn(formik.touched, "phone") && Boolean(getIn(formik.errors, "phone"))}
                                helperText={getIn(formik.touched, "phone") && getIn(formik.errors, "phone")}
                            />
                        </Grid>
                        <Grid container item md={3} xs={12}>
                            <TextField
                                fullWidth
                                required
                                name="phoneTwo"
                                id="description"
                                label="Télephone 2"
                                variant="outlined"
                                value={formik.values.phoneTwo}
                                onChange={formik.handleChange}
                                error={getIn(formik.touched, "phoneTwo") && Boolean(getIn(formik.errors, "phoneTwo"))}
                                helperText={getIn(formik.touched, "phoneTwo") && getIn(formik.errors, "phoneTwo")}
                            />
                        </Grid>
                        <Grid container item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel required shrink={true} id="govs">Governerat</InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    value={activeGov.gov}
                                    label="Governerat   s"
                                    onChange={(event) => filterSelectedGov(event.target.value)}
                                >

                                    {govs && govs.map(gov => {
                                        return (
                                            <MenuItem key={`store-${gov.id}`} value={gov.id}>{gov.name}</MenuItem>)
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel required shrink={true} id="deleg">Delegations</InputLabel>
                                <Select
                                    value={formik.values.deleg_id}
                                    name={"deleg_id"}
                                    label="Delegations"
                                    onChange={formik.handleChange}
                                >

                                    {activeGov.delegations && activeGov.delegations.map(gov => {
                                        return (
                                            <MenuItem key={`store-${gov.id}`} value={gov.id}>{gov.name}</MenuItem>)
                                    })}

                                </Select>
                                <FormHelperText
                                    error={getIn(formik.touched, "deleg_id") && Boolean(getIn(formik.errors, "deleg_id"))}>{getIn(formik.touched, "deleg_id") && getIn(formik.errors, "deleg_id")}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={6}>
                            <TextField
                                required
                                fullWidth
                                name="address"
                                label="Address"
                                variant="outlined"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={getIn(formik.touched, "address") && Boolean(getIn(formik.errors, "address"))}
                                helperText={getIn(formik.touched, "address") && getIn(formik.errors, "address")}
                            />
                        </Grid>
                        <Grid container item xs={6}>
                            <TextField
                                required
                                fullWidth
                                name="mf"
                                label="Matricule Fiscale Client"
                                variant="outlined"
                                value={formik.values.mf}
                                onChange={formik.handleChange}
                                error={getIn(formik.touched, "mf") && Boolean(getIn(formik.errors, "mf"))}
                                helperText={getIn(formik.touched, "mf") && getIn(formik.errors, "mf")}
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={formik.submitForm} autoFocus>
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}