import React from "react";
import {Grid, Paper, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {calculateTotal, sousTotal} from "../../../../helpers/functions";


export default function Itemslist({items, setItems, discount,deliveryPrice,formik}) {


    function deleteItem(product_id) {
        const filter = items.filter((i) => i.product.id !== product_id);
        setItems(filter);
    }

    function calculateProductTP(price, qte) {
        return price * qte
    }

    return (

        items &&
        <Paper sx={{width: "100%", padding: 2}}>
            <Grid xs={12} container item spacing={2}>
                <Grid container item xs={12}>
                    <Typography fontWeight="bold">Liste de produits :</Typography>
                </Grid>
                <Grid container sx={{color: (t) => (t.palette.primary.main), fontWeight: "bold"}} item xs={12}>
                    <Grid xs={4} container item>Produit</Grid>
                    <Grid xs={2} container item>Quantité</Grid>
                    <Grid xs={2} container item>Prix unitaire</Grid>
                    <Grid xs={2} container item>Prix Total</Grid>
                    <Grid xs={2} container item>Actions</Grid>
                </Grid>
                {items.map((item) => {

                    return (
                        <Grid container sx={{height: 70}} item key={`item-${item.product.id}`} xs={12}>
                            <Grid container item key={`item-${item.product.id}`} xs={12}>
                                <Divider sx={{backgroundColor: "red", width: "100%", height: 3}}/>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid xs={4} container item>{item.product.name}</Grid>
                                <Grid xs={2} container item>
                                    {item.quantity}
                                </Grid>
                                <Grid xs={2} container item>{parseInt(item.product.price).toFixed(3)}</Grid>
                                <Grid xs={2} container
                                      item>{calculateProductTP(item.product.price, item.quantity).toFixed(3)}</Grid>
                                <Grid xs={2} container item>
                                    <DeleteForeverIcon
                                        sx={{
                                            cursor: "pointer",
                                            "&:hover": {
                                                color: (t) => (t.palette.primary.main)
                                            }
                                        }}
                                        onClick={() => deleteItem(item.product.id)}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    )
                })}

                <Grid container item xs={12} justifyContent={'flex-end'}>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        Sous-total :
                    </Grid>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        {sousTotal(items).toFixed(3)}
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent={'flex-end'}>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        Remise :
                    </Grid>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        {discount ? ((sousTotal(items) / 100) * discount).toFixed(3) : '0.000'}
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent={'flex-end'}>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        Livraison :
                    </Grid>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        {formik.values.order.freeShipping ? "0.000" : deliveryPrice.toFixed(3)}
                    </Grid>
                </Grid>
                <Grid container item xs={12} sx={{color : (t) => t.palette.primary.main, fontWeight : "bold"}} justifyContent={'flex-end'}>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        Total :
                    </Grid>
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        { formik.values.order.paymentStatus === 2 ?
                            calculateTotal(items,discount,formik.values.order.freeShipping === 1 ? 0 : deliveryPrice).toFixed(3)
                            :
                            formik.values.order.freeShipping === 1 ? "0.000" : deliveryPrice.toFixed(3)
                        }
                    </Grid>
                </Grid>

            </Grid>
        </Paper>


    )
}