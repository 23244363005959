import React, {useContext} from 'react';
import {useHistory} from "react-router-dom";
import * as yup from 'yup';
import {useFormik} from "formik";
import {Grid, Typography} from "@mui/material";
import UserContext from "../../../context/userContext";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DropZonePreview from "../../../component/dropZone";
import {createProducts} from "../../../api/products";
import {routesName} from "../../../router/router";
import AlertsContext from "../../../context/alertsContext";

export default function CreateProduct({setSnackbar}) {
    const {accessToken} = useContext(UserContext);
    const {setProgressBar,setSnackBar} = useContext(AlertsContext);
    const history = useHistory();
    const validationSchema = yup.object({
        name : yup.string().required("Le nom de produit est obligatoire"),
        cost : yup.number().required("le coût de la produit est obligatoire"),
        description : yup.string().required("la description est obligatoire"),
        price : yup.number().required("le prix du vente de la produit est obligatoire")
    });

    const formik = useFormik({
        validationSchema : validationSchema,
        initialValues : {
            name : "",
            cost : "",
            description : "",
            price : "",
            files : [],
        },
        onSubmit : (values) => {
            setProgressBar(true);
            createProducts(accessToken,values)
                .then((res) => {
                    setSnackBar({
                        status : true,
                        message : res.data.message,
                        severity : "success"
                    });
                    setProgressBar(false);
                    history.push(routesName.products.index)
                });

        }
    });

    function setFiles(files) {
        formik.setFieldValue('files',files);
    }
    return (
        <Grid container item rowSpacing={2} columnSpacing={2} xs={12}>
            <Grid container item xs={12}>
                <Typography variant={"h6"}>
                    Nouveau Produits :
                </Typography>
            </Grid>
            <Grid container item md={6} xs={12}>
                <TextField
                    fullWidth
                    id="name"
                    label="Nom de la produit"
                    type="text"
                    variant="outlined"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.errors.name}
                />
            </Grid>
            <Grid container item md={3} xs={12}>
                <TextField
                    fullWidth
                    id="cost"
                    label="Coût de la produit"
                    type="text"
                    variant="outlined"
                    value={formik.values.cost}
                    onChange={formik.handleChange}
                    error={formik.touched.cost && Boolean(formik.errors.cost)}
                    helperText={formik.errors.cost}
                />
            </Grid>
            <Grid container item md={3} xs={12}>
                <TextField
                    fullWidth
                    id="price"
                    label="Prix de la produit"
                    type="text"
                    variant="outlined"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.errors.price}
                />
            </Grid>
            <Grid container item md={12} xs={12}>
                <TextField
                    fullWidth
                    multiline
                    minRows={2}
                    id="description"
                    label="Description de la produit"
                    type="textarea"
                    variant="outlined"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.errors.description}
                />
            </Grid>
            <Grid container item md={12} xs={12}>
                <DropZonePreview setFiles={(files) => setFiles(files)}/>
            </Grid>
            <Grid container justifyContent={"center"} item md={12} xs={12}>
                <Button size={"large"} variant={"contained"} color={"primary"} onClick={formik.submitForm}>Ajouter</Button>
            </Grid>
        </Grid>
    );
}
