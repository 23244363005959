import React, {useContext, useEffect, useState} from "react";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import UserContext from "../../../context/userContext";
import apiCall from "../../../api/axios_config";


export default function ClientByGov() {

    const [govs, setGovs] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {accessToken} = useContext(UserContext);


    useEffect(() => {
        apiCall(accessToken)
            .get('/orders/orders_by_gov')
            .then((res) => setGovs(res.data))
            .catch((e) => {
            })
            .finally(() => setIsLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        !isLoading &&
        <ResponsiveContainer width="100%">
            <BarChart data={govs}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name" angle={90} height={100} dy={30}/>
                <YAxis dataKey="clients_count" type="number" domain={[0, 'auto']}/>
                <Tooltip/>
                <Legend/>
                <Bar dataKey="clients_count" name="commandes" fill="#ed343c"/>
            </BarChart>
        </ResponsiveContainer>
    )
}


