import React, {useContext, useEffect, useState} from "react";
import apiCall from "../../../api/axios_config";
import UserContext from "../../../context/userContext";
import {Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useFormik} from "formik";
import axios from "axios";
import PickUp from "./create_form/store";
import Specification from "./create_form/specification";
import ClientInformation from "./create_form/clientInformation";
import Items from "./create_form/items";
import Itemslist from "./create_form/itemslist";
import DiscountSection from "./create_form/discount";
import OldClient from "./create_form/oldclient";
import {calculateTotal} from "../../../helpers/functions";
import AlertsContext from "../../../context/alertsContext";
import {useHistory} from "react-router-dom";
import Loader from "../../../component/common/loader";
import {useParams} from "react-router";
import moment from "moment";


export default function EditOrder() {
    const {id} = useParams();
    const [stores, setStores] = useState({});
    const [govs, setGovs] = useState(null);
    const [products, setProducts] = useState({});
    const [activeGov, setActiveGov] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentQte, setCurrentQte] = useState(1);
    const [currentProduct, setCurrentProduct] = useState("");
    const [deliveryCompanies, setDeliveryCompanies] = useState({});
    const [items, setItems] = useState(null);
    const [itemsError, setItemsError] = useState(false);
    const [order, setOrder] = useState(null);

    const [clientList, setClientList] = useState(null);
    const [oldClient, setOldClient] = useState(null);

    const {accessToken} = useContext(UserContext);
    const {setProgressBar,setSnackBar} = useContext(AlertsContext)

    const history = useHistory();

    function updateOldClient(data){
        setOldClient(data);
        formik.setFieldValue('client.deleg_id',data.deleg_id)
        formik.setFieldValue('client.fullName',data.fullName)
        formik.setFieldValue('client.phone',data.phone)
        formik.setFieldValue('client.phoneTwo',data.phoneTwo)
        formik.setFieldValue('client.address',data.address)
        formik.setFieldValue('client.mf',data.mf)
    }
    function deleteOldClient(){
        setOldClient(null);
        formik.setFieldValue('client.deleg_id',"");
        formik.setFieldValue('client.fullName',"");
        formik.setFieldValue('client.phone',"");
        formik.setFieldValue('client.phoneTwo',"");
        formik.setFieldValue('client.address',"");
        formik.setFieldValue('client.mf',"");
    }
    // const validationSchema = yup.object({
    //     client:
    //         yup.object().shape({
    //                 fullName: yup.string().required("le nom et prénom du client est obligatoire"),
    //                 phone: yup.number("le numéro de télephone doivent être un numéro").required("Télephone obligatoire"),
    //                 phoneTwo: yup.number("le numéro de télephone doivent être un numéro"),
    //                 address: yup.string().required('addresse est obligatioire'),
    //                 deleg_id: yup.number().required("delegation est obligatoire"),
    //             }
    //         ),
    //     order:
    //         yup.object().shape({
    //             store_id: yup.number().required(),
    //             // discount : yup.number().required(),
    //             // packageSize: yup.number().required(),
    //             // paymentStatus: yup.boolean().required(),
    //             // amount: yup.number().required(),
    //             // descProduit: yup.string().required(),
    //             // isExchange: yup.string().required(),
    //             // isFragile: yup.boolean().required(),
    //             // freeShipping: yup.boolean().required(),
    //             // deliveryCompany : yup.object().required(),
    //         })
    //
    // });

    const formik = useFormik({
        // validationSchema: validationSchema,
        initialValues: {
            order: {
                store_id: 1,
                discount: 0,
                packageSize: 1,
                paymentStatus: 2,
                amount: "0",
                descProduit: "Jeux Educatifs",
                isExchange: 1,
                isFragile: 1,
                freeShipping: 0,
                deliveryCompany: deliveryCompanies[0]
            },
            client: {
                clientId: null,
                fullName: "",
                phone: "",
                phoneTwo: "",
                address: "",
                deleg_id: "",
                mf : "",
            },
            items: []
        },
        onSubmit: (values,{setFieldError}) => {
            setProgressBar(true);
            if (items) {
                var data = new FormData();

                // data.append('client[clientId]', values.client.clientId)

                data.append('deliveryDate', moment().format("Y-MM-D"));
                data.append('discount', values.order.discount);
                data.append('store_id', values.order.store_id);
                data.append('packageSize', values.order.packageSize);
                data.append('paymentStatus', values.order.paymentStatus);
                data.append('descProduit', values.order.descProduit);
                data.append('amount', calculateTotal(items,values.order.discount,values.order.freeShipping === 1 ? 0 : values.order.deliveryCompany.public_price));
                data.append('free_delivery',values.order.freeShipping)
                data.append('isExchange',values.order.isExchange);

                items.forEach((item, i) => {
                    data.append(`items[${i}][product_id]`, item.product_id);
                    data.append(`items[${i}][quantity]`, item.quantity);
                });

                const valeurs = {
                    amount :
                        values.order.paymentStatus === 2 ?
                            calculateTotal(items,values.order.discount,values.order.freeShipping === 1 ? 0 : values.order.deliveryCompany.public_price)
                            : 0,
                    isExchange: values.order.isExchange,
                    free_delivery : values.order.freeShipping,
                    descProduit: values.order.descProduit,
                    paymentStatus: values.order.paymentStatus,
                    packageSize: values.order.packageSize,
                    store_id : values.order.store_id,
                    discount:  values.order.discount,
                    items : items
                }
                apiCall(accessToken).post(`/orders/editer/${id}`, valeurs)
                    .then(res => {
                        setSnackBar({
                            status: true,
                            message : "La Commande a été modifié avec succès",
                            severity: "success"
                        })
                        history.push('/orders/list');
                    })
                    .catch((error) => {
                        setSnackBar({
                            status: true,
                            message : "Un erreur est produit lors de la modification de la commande",
                            severity: "error"
                        })
                    }).finally(() => {
                            setProgressBar(false);

                })
                ;
            } else {
                setItemsError(true);
                setProgressBar(false);

            }

        }
    });

    useEffect(() => {
        const fetchData = async () => {
            const getStores = apiCall(accessToken).get("/stores/all");
            const getGovs = apiCall(accessToken).get('/places/govs/all?withDeleg=true');
            // const getProducts = apiCall(accessToken).get('/products/all');
            const getDeliveryCompanies = apiCall(accessToken).get('/delivery_companies/all')
            const getStoreStock = apiCall(accessToken).get('/stores/get_stock/1')
            await axios.all([getStores, getGovs, getStoreStock, getDeliveryCompanies])
                .then(axios.spread((...responses) => {
                    const storeResponse = responses[0];
                    const govsResponse = responses[1];
                    const productsResponse = responses[2];
                    const deliveryCompaniesResponse = responses[3];
                    setStores(storeResponse.data.stores);
                    setGovs(govsResponse.data);
                    setProducts(productsResponse.data.stock);
                    setDeliveryCompanies(deliveryCompaniesResponse.data)
                    formik.setFieldValue('order.deliveryCompany', deliveryCompaniesResponse.data[0])
                })).catch(e => {
                })
                .finally(() => {
                });
            await apiCall(accessToken)
                .get(`/orders/single/${id}`)
                .then((res) => {
                    setOrder(res.data.order);

                })
                .catch((e) => {
                    if(e.response.status === 404) history.push('/404')
                })
                .finally(() => {
                });

            await setLoading(true)


        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(order !== null){
            formik.setFieldValue('order.store_id',order.store_id);
            formik.setFieldValue('order.discount',order.discount);
            formik.setFieldValue('client.fullName',order.client.fullName);
            formik.setFieldValue('client.phone',order.client.phone);
            formik.setFieldValue('client.phoneTwo',order.client.phoneTwo);
            formik.setFieldValue('client.address',order.client.address);
            formik.setFieldValue('client.mf',order.client.mf);
            formik.setFieldValue('order.freeShipping',order.free_delivery);
            formik.setFieldValue('order.delivery_price',order.delivery_price);
            formik.setFieldValue('order.paymentStatus',order.paymentStatus);
            formik.setFieldValue('order.isExchange',order.isExchange)
            const findGov = govs.find((gov) => gov.id === order.client.delegation.gov_id);
            setActiveGov({
                gov : order.client.delegation.gov_id,
                delegations : findGov.delegations
            });
            setOldClient(order.client);
            formik.setFieldValue('client.deleg_id',order.client.deleg_id);

            // const itemsToSet = [];
            // order.items.map((item) => {
            //     itemsToSet.push({
            //         product : {
            //             id : item.product.id,
            //             price : item.price,
            //             name : item.product.name,
            //             product : item.product
            //         },
            //         product_id : item.product.id,
            //         quantity: item.quantity
            //     });
            // });
            // setItems(itemsToSet);
            setItems(order.items)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    // const stores = apiCall(accessToken).get('/manager/check_user').then(r => console.log("r",r));
    return (
        loading ?
            <Grid container item rowSpacing={2} columnSpacing={2} xs={12}>
                <Grid container item xs={12}>
                    <Typography color={"primary"} variant={"h6"}>
                        Nouveau Commande :
                    </Typography>
                </Grid>
                {/*؛PickUp Section*/}

                <Grid container item xs={12}>
                    <PickUp stores={stores} formik={formik} deliveryCompanies={deliveryCompanies}/>
                </Grid>

                {/*Specifications*/}
                <Grid container item xs={12}>
                    <Specification formik={formik}/>
                </Grid>
                <Grid container item xs={12}>
                    {clientList && !oldClient && <OldClient govs={govs} setOldClient={setOldClient} clientsList={clientList}
                                                            setActiveGov={setActiveGov} formik={formik}/>}
                </Grid>
                {/*Client Informations*/}
                <Grid container item xs={12}>
                    {/*<EditClientModal oldClient={order.client} govs={govs}/>*/}
                    <ClientInformation deleteClientInfo={false} oldClient={oldClient} setClientList={setClientList} formik={formik} govs={govs}
                                       setActiveGov={setActiveGov} activeGov={activeGov} deleteOldClient={deleteOldClient} updateOldClient={updateOldClient}/>
                </Grid>

                {/*Discount Section*/}
                <Grid container item xs={12}>
                    <DiscountSection formik={formik}/>
                </Grid>

                {/*Add Items*/}
                <Grid container item xs={12}>
                    <Items formik={formik} itemsError={itemsError} currentQte={currentQte} products={products}
                           setCurrentQte={setCurrentQte} items={items} setItems={setItems} currentProduct={currentProduct}
                           setCurrentProduct={setCurrentProduct}/>
                </Grid>

                {/*Items List*/}
                <Grid container item xs={12}>
                    {items &&
                        <Itemslist
                            formik={formik}
                            items={items}
                            setItems={setItems}
                            discount={formik.values.order.discount}
                            deliveryPrice={formik.values.order.deliveryCompany.public_price}
                        />
                    }
                </Grid>


                <Grid container justifyContent={"center"} item md={12} xs={12}>
                    <Button size={"large"} variant={"contained"} color={"primary"}
                            onClick={formik.submitForm}>Editer</Button>
                </Grid>
            </Grid> : <Loader/>

    )
}