import React from "react";
import Alert from "@mui/material/Alert";


export default function NotificationMessage(props) {

    return(
        <Alert sx={{minWidth : 400}} {...props}>
            {props.body.toUpperCase()}
        </Alert>
    )
}