import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import UserContext from "../../../../context/userContext";
import apiCall from "../../../../api/axios_config";
import {useFormik} from "formik";
import {useParams} from "react-router";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {Paper, Skeleton, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import moment from "moment";
import Stack from "@mui/material/Stack";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import RecommendRoundedIcon from '@mui/icons-material/RecommendRounded';
import ApproveAction from "../../../../component/common/approveAction";
import Button from "@mui/material/Button";
import AlertsContext from "../../../../context/alertsContext";
import userInfos from "../../../../router/middleware/userInfos";

export default function Filling({storeId = null,css}) {
    const {id} = useParams();
    const store = id ? id : storeId
    const {setProgressBar} = useContext(AlertsContext);
    const [products, setProducts] = useState({});
    const [stockageHistory, setStockageHistory] = useState({});
    const [isLoading, setLoading] = useState(true);
    // const [selectedProduct, setSelectedProduct] = useState([]);
    const [pagination, setPagination] = useState({currentPage: 1})
    const {accessToken, userRole} = useContext(UserContext);
    const {setSnackBar} = useContext(AlertsContext);
    const handleProductInputChange = (valueArray) => {
        const productsID = valueArray.map(t => t.id);
        formik.setFieldValue('products', productsID);
    }
    useEffect(() => {
        console.log('id is ' + id);
        const getProducts = apiCall(accessToken).get('/products/all')

        const getStockage = apiCall(accessToken).get(`stores/stockage_history/${store}`)

        axios.all([getProducts, getStockage])
            .then(axios.spread((...res) => {
                const productsRes = res[0];
                const stockageRes = res[1];
                setProducts(productsRes.data.products);
                setStockageHistory(stockageRes.data.filling_history.data);
                setPagination((prev) => ({...prev, totalPage: stockageRes.data.filling_history.last_page}))
            }))
            .catch((e) => {
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        initialValues: {
            products: [],
            quantity: 0,
            store_id: store,
        },
        onSubmit: (values) => {
            apiCall(accessToken).post("/stores/stockage", values).then((res) => {
                setStockageHistory((prev) => ([...res.data.filling, ...prev]));
            }).then(() => {
                formik.setFieldValue('products', []);
                formik.setFieldValue('quantity', 0);
                setSnackBar({
                    status : true,
                    message : "stockage ajouté avec succssé",
                    severity : "success"
                });
            });
        }
    });

    function handleStockageHistoryDelete(id) {
        apiCall(accessToken)
            .post(`/stores/undo_stockage/${id}`)
            .then((res) => {
                setStockageHistory((prevState) => {
                    return prevState.filter((stock, index) => stock.id !== id)
                });
                setSnackBar({
                    status: true,
                    message : "Stockage supprimer avec succées",
                    severity: "success"
                });
            })
            .catch((e) => {

                setSnackBar({
                    status: true,
                    message : "Une Erreur est servenue lors de la suppression de stockage !",
                    severity: "error"
                });
            })
        ;
    }

    function handleStockageHistoryApproval(id, index) {
        apiCall(accessToken)
            .post(`/stores/approve_stockage/${id}`)
            .then((res) => {
                setStockageHistory((prev) => {
                    const newState = stockageHistory;
                    newState[index] = res.data.filling;
                    return [...newState];
                });
                setSnackBar({
                    status: true,
                    message : "Stockage approuver avec succés",
                    severity: "success"
                });
            })
            .catch(e => {

                setSnackBar({
                    status: true,
                    message : "Une Erreur est servenue lors de la suppression de stockage !",
                    severity: "error"
                });
            })


    }

    function getMoreData() {
        if (pagination.currentPage + 1 <= pagination.totalPage) {
            setProgressBar(true);
            apiCall(accessToken)
                .get(`stores/stockage_history/${store}?page=${pagination.currentPage + 1}`)
                .then((res) => {
                    setStockageHistory((prev) => ([...prev, ...res.data.filling_history.data]));
                    setPagination((prev) => {
                        const nextPage = prev.currentPage + 1;
                        const lastPage = prev.totalPage
                        return({
                            currentPage: nextPage,
                            totalPage : lastPage
                        })
                    })
                })
                .catch((e) => {
                }).finally(() => {setProgressBar(false)})
        }
    }

    return (
        <Grid container item xs={12} spacing={2}>
            {userRole === "admin" &&
                <>
                    <Grid container item xs={6}>
                        {products &&
                            <Autocomplete
                                multiple
                                fullWidth
                                name="productsID"
                                options={products}
                                getOptionLabel={(option) => option.name}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Produits"
                                        placeholder="Sélectionnez un ou plusieurs produits"
                                    />
                                )}
                                onChange={(event, value) => handleProductInputChange(value)}
                            />
                        }
                    </Grid>
                    <Grid container item xs={3}>
                        <TextField
                            label="quantity"
                            name="quantity"
                            value={formik.values.quantity}
                            variant="outlined"
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid container item xs={3} alignItems="center">
                        <AddCircleRoundedIcon
                            onClick={formik.submitForm}
                            sx={{
                                fontSize: 40,
                                cursor: "pointer",
                                color: (t) => t.palette.primary.main,
                                "&:hover": {
                                    color: (t) => t.palette.primary.dark
                                }
                            }}
                        />
                    </Grid>
                </>
            }
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12}>
                    <Paper
                        elevation={8}
                        sx={{
                            width: "100%",
                            backgroundColor: t => t.palette.primary.main,
                            padding: 1,
                            color: "white",
                            zIndex : (t) => t.zIndex.appBar - 1
                        }}
                    >
                        <Grid container item xs={12}>
                            <Grid container item xs={5} sx={{borderRight: "1px solid white"}}>
                                <Typography>Produits</Typography>
                            </Grid>
                            <Grid container item xs={3} justifyContent="center" sx={{borderRight: "1px solid white"}}>
                                <Typography>Date</Typography>
                            </Grid>
                            <Grid container item xs={2} justifyContent="center" sx={{borderRight: "1px solid white"}}>
                                <Typography>QTE</Typography>
                            </Grid>
                            <Grid container item xs={2} justifyContent="center">
                                <Typography>Actions</Typography>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>

                <Grid container item xs={12} sx={{...css}} spacing={2}>
                    {!isLoading ?
                        stockageHistory.map((stock, index) => (
                            <Grid key={`stockage-${stock.id}`} container item xs={12}>
                                <Paper
                                    elevation={8}
                                    sx={{
                                        width: "100%",
                                        padding: 1,
                                    }}
                                >
                                    <Grid container item xs={12}>
                                        <Grid container item xs={5} sx={{borderRight: "1px solid red"}}>
                                            <Typography>{stock.product_name}</Typography>
                                        </Grid>
                                        <Grid container item xs={3} justifyContent="center"
                                              sx={{borderRight: "1px solid red"}}>
                                            <Typography>{moment(stock.filling_date).format("DD-MM-Y")}</Typography>
                                        </Grid>
                                        <Grid container item xs={2} justifyContent="center"
                                              sx={{borderRight: "1px solid red"}}>
                                            <Typography>{stock.quantity}</Typography>
                                        </Grid>
                                        <Grid container item xs={2} justifyContent="center">
                                            <Stack direction="row">
                                                {stock.approved ?
                                                    <Tooltip title="Stockage déjà approuvé">
                                                        <RecommendRoundedIcon color="success"/>
                                                    </Tooltip>
                                                    :
                                                    <ApproveAction
                                                        title={"Vous êtes sur le point de approuver l'historique de stockage ! voulez-vous continuer?"}
                                                        approveAction={() => handleStockageHistoryApproval(stock.id, index)}>

                                                        <CheckCircleRoundedIcon
                                                            sx={{
                                                                cursor: "pointer",
                                                                color: t => t.palette.success.main,
                                                                "&:hover": {
                                                                    color: t => t.palette.success.dark
                                                                }
                                                            }}
                                                        />

                                                    </ApproveAction>

                                                }
                                                {
                                                    userRole === "admin" &&
                                                    <ApproveAction
                                                        title={"vous êtes sur le point de supprimer l'historique de stockage ! voulez-vous continuer?"}
                                                        approveAction={() => handleStockageHistoryDelete(stock.id, index)}>
                                                        <DeleteForeverRoundedIcon
                                                            sx={{
                                                                cursor: "pointer",
                                                                color: t => t.palette.primary.main,
                                                                "&:hover": {
                                                                    color: t => t.palette.primary.dark
                                                                }
                                                            }}
                                                        />
                                                    </ApproveAction>
                                                }
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))

                        : [...Array(10)].map((j,index) => (
                            <Grid key={`skeleton-${index}`} container sx={{minHeight : 70}} item xs={12}>
                                <Paper
                                    elevation={8}
                                    sx={{
                                        width: "100%",
                                        padding: 1,
                                    }}
                                >
                                    <Grid container item xs={12}>
                                        <Grid container item xs={5} sx={{borderRight: "1px solid red"}}>
                                            <Skeleton variant="rectangular" sx={{width: "80%", height: 20, borderRadius: 2}}/>
                                        </Grid>
                                        <Grid container item xs={3} justifyContent="center"
                                              sx={{borderRight: "1px solid red"}}>
                                            <Skeleton variant="rectangular" sx={{width: "80%", height: 20, borderRadius: 2}}/>

                                        </Grid>
                                        <Grid container item xs={2} justifyContent="center"
                                              sx={{borderRight: "1px solid red"}}>
                                            <Skeleton variant="rectangular" sx={{width: "80%", height: 20, borderRadius: 2}}/>

                                        </Grid>
                                        <Grid container item xs={2} justifyContent="center">
                                            <Skeleton variant="rectangular" sx={{width: "80%", height: 20, borderRadius: 2}}/>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))
                    }
                </Grid>
                {!isLoading && <Grid container item xs={12} justifyContent="center">
                    {pagination.currentPage === pagination.totalPage ? <p>Rien à afficher</p> : <Button variant={"contained"} onClick={getMoreData}>Show more</Button>}
                </Grid>}

            </Grid>
        </Grid>
    );
}

