import React from "react";
import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import {Image} from "mui-image";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {Area, AreaChart, CartesianGrid, XAxis, YAxis} from "recharts";
import Tooltip from "@mui/material/Tooltip";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";




export default function ProductStats() {


    return(
        <Grid container item xs={12} sx={{padding : 4}} component={Paper}>
            <Grid container item xs={12} lg={12} justifyContent={"center"}>
                <Stack sx={{width : "100%"}} alignItems={"center"} spacing={2}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell >Produit</TableCell>
                                    <TableCell align="right">Total Vente</TableCell>
                                    <TableCell align="right">Total Gain</TableCell>
                                    <TableCell align="right">best Month Sell</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/*<TableRow*/}
                                {/*    key={row.name}*/}
                                {/*    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}*/}
                                {/*>*/}
                                {/*    <TableCell component="th" scope="row">*/}
                                {/*        {row.name}*/}
                                {/*    </TableCell>*/}
                                {/*    <TableCell align="right">{row.calories}</TableCell>*/}
                                {/*    <TableCell align="right">{row.fat}</TableCell>*/}
                                {/*    <TableCell align="right">{row.carbs}</TableCell>*/}
                                {/*    <TableCell align="right">{row.protein}</TableCell>*/}
                                {/*</TableRow>*/}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Grid>
        </Grid>
    )
}