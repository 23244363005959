import React, {useContext, useState} from 'react';
import Grid from "@mui/material/Grid";
import NotificationMessage from "../../component/common/notificationMessage";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useFormik} from "formik";
import axios from "axios";
import {apiUrl} from "../../common";
import {hashedKey} from "../../helpers/hashedKey";
import * as yup from "yup";
import UserContext from "../../context/userContext";
import AlertsContext from "../../context/alertsContext";
import {useHistory} from "react-router-dom";
import Container from "@mui/material/Container";
import CustomPaper from "../../component/common/custom_paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import useEventListener from "../../hooks/useEventListiner";
import ProgressBar from "../../component/common/progressBar";


//Form Validations
const validationSchema = yup.object({
    email: yup
        .string()
        .email('Veuillez entrer une adresse email valide')
        .required('le nom de la produit est obligatoire'),

    password : yup
        .string('').required('Entrer votre mot de passe'),
});

export default function LoginForm(){
    const [notification , setNotification] = useState({status : false,message : ""});
    const {setUser} = useContext(UserContext);
    const {progressBar,setProgressBar} = useContext(AlertsContext);
    const history = useHistory();

    const handler = ({ key }) => {
        if (["13","Enter"].includes(String(key))) {
            formik.submitForm();
        }
    };
    useEventListener('keydown',handler)
    const formik = useFormik({
        initialValues : {
            "email" : "",
            "password" : "",
        },
        validationSchema : validationSchema,
        onSubmit : (values => {
            setProgressBar(true);
            setNotification({
                status : false,
                message : ""
            })
            axios.post(
                apiUrl + "manager/login",
                {
                    email : values.email,
                    password : values.password
                },
                {
                    headers : {
                        "Accept" : "application/json"
                    }
                }
            )
                .then(res => {


                    const localStorageItem = {
                        isAuth : true,
                        accessToken : res.data.accessToken,
                        user: res.data.user,
                    };
                    localStorage.setItem(
                        hashedKey("userData"),
                        JSON.stringify(localStorageItem)
                    );

                    setUser({
                        isAuth : true,
                        accessToken: res.data.accessToken,
                        user : res.data.user

                    });
                    setProgressBar(false);
                    history.push('/');

                })
                .catch(error => {
                    if(error.response) {
                        if(error.response.status === 401){
                            setNotification({
                                status: true,
                                message : error.response.data.message,
                                severity : "error"
                            });
                        }else {
                            setNotification({
                                status: true,
                                message : "Un erreur générale est survenue ! veuillez réessayer plus tard",
                                severity : "warning"
                            });
                        }
                    }
                    setProgressBar(false);

                })
        })
    });

    return(
        <Grid container sx={{height : "100vh", backgroundColor : "#111827"}} alignContent="center" item xs={12}>
            {progressBar &&
                <ProgressBar css={{position : "absolute", top : 0 , left : 0}}/>
            }
            <Container maxWidth="lg">
                <CustomPaper elevation={18} css={{padding :{xs : 4 , sm : 18}}}>
                    <Grid container item xs={12}  justifyContent={{xs : "center" , lg : "flex-start"}} alignContent="center" alignItems="center" spacing={4}>
                        <Grid container item xs={12} md={4} justifyContent={{xs : "center" , md : "center"}}>
                            <img width={200} height={250} src={"/logo.png"} alt="logo"/>
                        </Grid>
                        <Grid container item xs={12}  md={6} alignItems="flex-start" justifyContent="center">
                                <Grid container item  xs={12} spacing={2}>
                                    <Grid item container justifyContent="center" alignContent="center" xs={12}>
                                        <Typography sx={{fontSize : { xs : 15 , sm : 20}}} >Login</Typography>
                                        <Divider sx={{borderWidth : 5 , borderColor : (theme) => theme.palette.primary.main}} variant="middle"/>
                                        <Typography sx={{fontSize : { xs : 15,sm : 20}}} color="primary">Sghiri Manager</Typography>
                                    </Grid>
                                    {notification.status &&
                                        <Grid container item xs={12} justifyContent="center">
                                            <NotificationMessage  body={notification.message} variant="filled" severity={notification.severity}/>
                                        </Grid>
                                    }
                                    <Grid container item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="email"
                                            label="Email"
                                            type="email"
                                            autoComplete="current-password"
                                            variant="outlined"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.errors.email}
                                        />
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="password"
                                            label="Password"
                                            type="password"
                                            autoComplete="current-password"
                                            variant="outlined"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                            helperText={formik.errors.password}
                                        />
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Button variant="contained" color="primary" onClick={formik.submitForm}>
                                            Se connecter
                                        </Button>
                                    </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                </CustomPaper>
            </Container>
        </Grid>
    )
}