import React, {useContext, useEffect, useState} from "react";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import UserContext from "../../../context/userContext";
import apiCall from "../../../api/axios_config";


export default function ProductsSales() {

    const [productSales, setProductSales] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {accessToken} = useContext(UserContext);

    const getMaxSales = () => {
        const salesArraye = [];
        productSales.forEach((product) => {
            salesArraye.push(product.sales);
        });
        return Math.max(...salesArraye) + 25
    }
    useEffect(() => {
        apiCall(accessToken)
            .get('/orders/products_sales')
            .then((res) => setProductSales(res.data))
            .catch((e) => {
            })
            .finally(() => setIsLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        !isLoading &&
        <ResponsiveContainer width="100%">
            <BarChart data={productSales}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis dataKey="sales" type="number" domain={[0, getMaxSales]}/>
                <Tooltip/>
                <Legend/>
                <Bar dataKey="sales" fill="#ed343c"/>
            </BarChart>
        </ResponsiveContainer>
    )
}


