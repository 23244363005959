import axios from "axios";
import {apiUrl} from "../common";

export function multipartHeaders  (accessToken)   {
        return {
            "Accept": "application/json",
            "Authorization": "Bearer " + accessToken,
            'Content-Type': 'multipart/form-data'
        }
};
export function headers (accessToken)   {
    return {
        "Accept": "application/json",
        "Authorization": "Bearer " + accessToken,
    }
};
export async function getProduct(accessToken,id) {
    return await axios.get(
        apiUrl + "manager/products/single/" + id,
        {
            headers : headers(accessToken)
        }
        )
}

export  async function getAllProduct(accessToken,phone = null){

    return await axios.get(apiUrl + "manager/products/all",
        {
            headers: headers(accessToken)
        }
    );
}

export async function createProducts(accessToken,values) {
    let formData = new FormData();

    formData.append("name",values.name);
    formData.append("price",values.price);
    formData.append("cost",values.cost);
    formData.append("description",values.description);
    for (let i = 0; i < values.files.length; i++) {
        formData.append("files[]", values.files[i]);
    }

    return  await axios.post(
        apiUrl + "manager/products/create",
        formData,
        {
            headers : multipartHeaders(accessToken)
        }
    );
}


export async function deleteProduct(accessToken,productId) {

    return await axios.delete(
        apiUrl + "manager/products/delete/" + productId,
        {
            headers : headers(accessToken)
        }
    );
}



export async function editProduct(accessToken,productId,values) {
    let formData = new FormData();

    formData.append("name",values.name);
    formData.append("price",values.price);
    formData.append("cost",values.cost);
    formData.append("description",values.description);
    for (let i = 0; i < values.files.length; i++) {
        formData.append("files[]", values.files[i]);
    }

    return await axios.post(
        apiUrl + "manager/products/update/" + productId,
        formData,
        {
            headers : multipartHeaders(accessToken)
        }
    );
}

export async function deleteProductImage(accessToken,image,productId) {

    return await axios.post(
        apiUrl + "manager/products/delete_image",
        {image : image , id : productId},
        {
            headers: headers(accessToken)
        }
    )

}