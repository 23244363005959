import React, {useContext} from "react";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import Box from "@mui/material/Box";
import AlertsContext from "../../context/alertsContext";


export default function ProgressBar({css}) {
    const {progressBar} = useContext(AlertsContext);
    return(
        progressBar ? <Box sx={{ width: '100%' ,...css}} style={{zIndex : 9999}}>
            <LinearProgress color={"primary"}/>
        </Box> : ""
    )

}