import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from "@mui/material/Grid";

export default function SortButton({stock,setFiltredStock,currentFilter}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const  filterByDispoASC = () => {
        const toSort = [].concat(stock).sort((a,b) => a.quantity - a.sales > b.quantity - b.sales ? 1 : -1);
        setFiltredStock(toSort,"dispoASC");
    }
    const  filterByDispoDESC = () => {
        const toSort = [].concat(stock).sort((a,b) => a.quantity - a.sales < b.quantity - b.sales ? 1 : -1);
        setFiltredStock(toSort,"dispoDESC");
    }

    const filterAtoZ = () => {
        const toSort = [].concat(stock).sort((a,b) =>  a.name.localeCompare(b.name) >  0 ? 1 : -1);
        setFiltredStock(toSort,"AtoZ");
    }
    const filterZtoA = () => {
        const toSort = [].concat(stock).sort((a,b) => a.name.localeCompare(b.name) <  0 ? 1 : -1);
        setFiltredStock(toSort,"ZtoA");
    }


    return (
        <Grid container item xs={3} justifyContent={"flex-end"}>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Trier par
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem disabled={currentFilter === "dispoASC"} onClick={filterByDispoASC}>DISPO inf à sup</MenuItem>
                <MenuItem disabled={currentFilter === "dispoDESC"} onClick={filterByDispoDESC}>DISPO sup à inf</MenuItem>
                <MenuItem disabled={currentFilter === "AtoZ"} onClick={filterAtoZ}>A à Z</MenuItem>
                <MenuItem disabled={currentFilter === "ZtoA"} onClick={filterZtoA}>Z à A</MenuItem>
            </Menu>
        </Grid>
    );
}