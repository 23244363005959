import React from "react";
import {Paper, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";


export default function OldClient({govs,formik,setOldClient,clientsList,setActiveGov}) {

    function handleClientClick(client) {
        formik.setFieldValue("client.fullName",client.fullName);
        formik.setFieldValue("client.phone",client.phone);
        if(client.phoneTwo) formik.setFieldValue("client.phoneTwo",client.phoneTwo);
        formik.setFieldValue("client.address",client.address);
        formik.setFieldValue("client.clientId",client.id);
        filterSelectedGov(client.delegation.gov.id)
        setOldClient(client);
        formik.setFieldValue('client.deleg_id', client.delegation.id);

    }
    function filterSelectedGov(id) {
        const filtred = govs.find((gov) => gov.id === id);
        setActiveGov({
            gov: filtred.id,
            delegations: filtred.delegations
        })

    }

    return (

        <Paper sx={{width: "100%", padding: 2}}>
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12}>
                    <Typography variant={"body1"}>
                        Ancien client :
                    </Typography>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    { clientsList && clientsList.map((client) => (
                        <Grid key={client.phone} container item xs={3}>
                            <Button onClick={() => handleClientClick(client)}  variant="contained">
                                <Typography variant="body2" noWrap>{client.fullName}</Typography>
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Paper>

    )
}