import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {DashboardLayout} from "../../layout/v2/dashboard_layout";
import UserContext from "../../context/userContext";

const PrivateRoute = ({component: Component, withLayout = true, ...rest}) => {

    const {isAuth} = useContext(UserContext);
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /login page
        <Route  {...rest} render={(props) => (
            isAuth?
                withLayout ?
                <DashboardLayout>
                    <Component {...props} />
                </DashboardLayout>
                    :
                    <Component {...props} />
                : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;