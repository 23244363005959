import React from "react";
import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import OrderInfos from "./components/infos";
import DeliveryStatus from "./components/delivery_status";
import SelectOrder from "./components/selectOrder";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Stack from "@mui/material/Stack";
import OrderActions from "./actions";


export default function MobileTable({orders}) {

    return (
        <Grid container item xs={12} spacing={2}>
            {orders.map((order) => (
                <Grid container item xs={12} alignItems="center">
                    <Paper elevation={20} style={{width : "100%",height : 200,padding : 20,borderTop : (t) => `solid ${t.palette.primary.main}`}}>
                        <Grid container item xs={12} spacing={2}>
                            <Grid container item xs={2} alignContent="center" alignItems="center">

                                <Grid container item xs={12} justifyContent="center">
                                    <Stack alignItems="center" justifyContent="center">
                                        <SelectOrder order={order}/>
                                        <Typography fontWeight={500} fontSize={13}>{order.commandeNumber}</Typography>
                                        <Typography fontSize={12} fontWeight={400} color="primary">{moment(order.deliveryDate).format('D-MM-Y')}</Typography>
                                    </Stack>
                                </Grid>

                            </Grid>
                            <Grid container item xs={10} >
                                <Grid container item xs={12} alignItems="center" spacing={2}>
                                    <Grid container item xs={12}>
                                        <Stack style={{width : "100%"}} alignItems="center"  direction={"row"} spacing={1}>
                                            <Typography variant={"h6"} noWrap>{order.client.fullName}</Typography>
                                            <Typography variant={"h6"} noWrap sx={{fontSize : 13}} color="primary">{order.client.phone}  {order.client.phoneTwo && "- " + order.client.phoneTwo}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid container item xs={3} justifyContent="center">
                                        <Typography fontWeight={600} color={"primary"}>{order.amount} DT</Typography>
                                    </Grid>
                                    <Grid container item xs={9} justifyContent="center">
                                        <Stack alignItems="center" justifyItems="center" spacing={1}>
                                            <DeliveryStatus order={order}/>
                                            <Typography NoWrap={true} fontWeight="bolder" align="center" variant="body2">
                                                {order.client.delegation.gov.name} - {order.client.delegation.name}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid container item xs={6} justifyContent="center">
                                        <OrderInfos order={order}/>
                                    </Grid>
                                    <Grid container item xs={6} justifyContent="center">
                                        <OrderActions order={order}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    )
}