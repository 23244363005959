import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Link, useHistory} from "react-router-dom";
import {DashboardLayout} from "../layout/v2/dashboard_layout";
import Button from "@mui/material/Button";


export default function PageNotFound(){
    const history = useHistory();
    return(
        <DashboardLayout>
            <Grid container item xs={12} sx={{height : "80vh"}} justifyContent="center"  alignContent="center"  spacing={2}>
                <Grid container item xs={12} justifyContent="center"  alignContent="center">
                    <img alt="locked" style={{width : "70%"}} src="/404.png"/>
                </Grid>
                <Grid container item xs={12} justifyContent="center"  alignContent="center">
                    <Stack  spacing={2} alignItems="center" justifyContent="center">
                        <Typography textAlign="center" variant={"h6"}>
                            Désolé, nous n'avons pas pu trouver cette page pour vous.
                            La page a peut-être bougé ou l'orthographe de l'adresse est peut-être incorrecte. <Link to="/"></Link>
                        </Typography>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => history.push('/')}
                        >
                            Retour à l'accueil
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}