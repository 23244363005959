import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import apiCall from "../../../api/axios_config";
import UserContext from "../../../context/userContext";
import {Checkbox, Hidden, Paper} from "@mui/material";
import DesktopTable from "./order_table/desktopTable";
import TabletTable from "./order_table/tabletTable";
import MobileTable from "./order_table/mobileTable";
import PaginationComponent from "../../../component/pagination";
import SkeletonTable from "./skeletonTable";
import TableActions from "./order_table/components/table_actions";
import SelectedOrderContext from "../../../context/selectedOrderContext";
import OrderContext from "../../../context/OrderContext";
import SideBarContext from "../../../context/sideBarContext";
import Divider from "@mui/material/Divider";


// function OrderTable({orders}){
//     return(
//         orders.map((order) => {
//              return <p>{order.nid}</p>
//         })
//     )
// }


export default function OrderList(){

    const [orders, setOrders] = useState(null);
    const [terminate, setTerminate] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage : 1,
        perPage : 50,
    });
    const {accessToken} = useContext(UserContext);
    const {setSidebarOpen} = useContext(SideBarContext);
    useEffect(() => {
        setSidebarOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(() => {
        setTerminate(false);
        apiCall(accessToken)
            .get(`/orders/all?per_page=${pagination.perPage}&withClient=true&withItems=true&page=${pagination.currentPage}`)
            // .get(`/orders/all?per_page=${pagination.perPage}&withClient=true&withItems=true`)
            .then(res => {
                setOrders(res.data.orders.data)
                setPagination((prevState) => ({
                    ...prevState,
                    totalPage: res.data.orders.last_page
                }))
            }).finally(() =>  setTerminate(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pagination.currentPage,pagination.perPage]);
    function handleCurrentPageChange(p){
        setPagination((prev) => ({
            ...prev,
            currentPage: p
        }))
    };
    function handlePerPageChange(v){
        setPagination((prev) => ({
            ...prev,
            perPage: v
        }));
    };

    function  handleSelectedOrders(order){
        if(selectedOrder){
            const isExist = selectedOrder.find((item) => item.id === order.id);
            if(isExist){
                let filteredArray =  selectedOrder.filter((item) => item.id !== order.id)
                setSelectedOrder(filteredArray);
            } else {
                setSelectedOrder((prev) => [...prev,{id : order.id , nid : order.nid}])
            }
        } else {
            setSelectedOrder([
                {
                    id : order.id,
                    nid : order.nid
                }
            ]);
        }
    }

    function handleSelectAllOrder (value){
            if(value) {
                const toAdd = [];
                orders.forEach((order) => {
                    if (order.nid === undefined){
                        toAdd.push({
                            id : order.id,
                        })
                    } else {
                        toAdd.push({
                            id : order.id,
                            nid : order.nid
                        })
                    }
                });
                setSelectedOrder(toAdd);
            } else {
                setSelectedOrder([]);
            }

    }
    return (
        <OrderContext.Provider value={{orders,setOrders}}>
            <SelectedOrderContext.Provider value={{selectedOrder,handleSelectedOrders,setSelectedOrder}}>
                <Grid container item xs={12}>
                    <TableActions pagination={pagination} setPerPage={handlePerPageChange}/>
                </Grid>
                <Hidden lgDown>
                    <Grid container item xs={12} sx={{marginTop : 5}}>
                        <Paper elevation={3}  sx={{width: "100%"}}>
                            <Grid container columnSpacing={1} item sx={{height : "70px",padding : 2,fontWeight : 700, color : (t) => t.palette.primary.main}} xs={12} alignItems="center">
                                <Grid container item justifyContent="center" xs={1}>
                                    <Checkbox disabled={!terminate} onClick={(e) => handleSelectAllOrder(e.target.checked)}/>
                                </Grid>
                                <Divider orientation="vertical"  />
                                <Grid container item justifyContent="center" xs={1}>
                                    N°
                                </Grid>
                                <Divider orientation="vertical"  />
                                <Grid container item justifyContent="center" xs={2}>
                                    Client
                                </Grid>
                                <Divider orientation="vertical"  />
                                <Grid container item justifyContent="center" xs={1}>
                                    Prix
                                </Grid>
                                <Divider orientation="vertical"  />
                                <Grid container item justifyContent="center" xs={2}>
                                    Infos
                                </Grid>
                                <Divider orientation="vertical"  />
                                <Grid container item justifyContent="center" xs={2}>
                                    Livraison Infos
                                </Grid>
                                <Divider orientation="vertical"  />
                                <Grid container item justifyContent="center" xs={2.8}>
                                    Actions
                                </Grid>
                            </Grid>

                        </Paper>
                    </Grid>
                </Hidden>
                {
                    terminate ?
                        <Grid container item xs={12} spacing={2}>
                            <Hidden lgDown>
                                <DesktopTable orders={orders}/>
                            </Hidden>
                            <Hidden lgUp smDown>
                                <TabletTable orders={orders}/>
                            </Hidden>
                            <Hidden smUp>
                                <MobileTable orders={orders}/>
                            </Hidden>
                            <Grid container item xs={12} justifyContent="flex-end">
                                <PaginationComponent pagination={pagination} handleCurrentPage={handleCurrentPageChange}/>
                            </Grid>
                        </Grid>

                        : <SkeletonTable/>
                }
            </SelectedOrderContext.Provider>
        </OrderContext.Provider>
    )
}