import axios from "axios";
import {apiUrl} from "../common";


const apiCall = (token) =>{
    return axios.create({
       baseURL: apiUrl + "manager",
       headers: {
           "Accept": "application/json",
           "Authorization": "Bearer " + token
       }
   });
}

export default apiCall;
