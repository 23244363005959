import React from "react";
import {Hidden, Paper, Skeleton} from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";


export default function SkeletonTable() {

    return (
        <>
            <Hidden lgDown>
                <Grid container item xs={12} rowSpacing={1}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
                        <Grid container item xs={12}>
                            <Paper elevation={3} sx={{width: "100%"}}>
                                <Grid container columnSpacing={1} item sx={{
                                    height: "70px",
                                    padding: 2,
                                    fontWeight: 700,
                                    color: (t) => t.palette.primary.main
                                }} xs={12} alignItems="center">
                                    <Grid container item justifyContent="center" xs={1}>
                                        <Skeleton variant="rectangular" sx={{width: "80%", height: 25, borderRadius: 10}}/>
                                    </Grid>
                                    <Divider orientation="vertical"/>
                                    <Grid container item justifyContent="center" xs={1}>
                                        <Skeleton variant="rectangular"
                                                  sx={{width: "80%", height: 25, borderRadius: 10}}/>
                                    </Grid>
                                    <Divider orientation="vertical"/>
                                    <Grid container item justifyContent="center" xs={2}>
                                        <Skeleton variant="rectangular"
                                                  sx={{width: "80%", height: 25, borderRadius: 10}}/>
                                    </Grid>
                                    <Divider orientation="vertical"/>
                                    <Grid container item justifyContent="center" xs={1}>
                                        <Skeleton variant="rectangular"
                                                  sx={{width: "80%", height: 25, borderRadius: 10}}/>
                                    </Grid>
                                    <Divider orientation="vertical"/>
                                    <Grid container item justifyContent="center" xs={2}>
                                        <Skeleton variant="rectangular"
                                                  sx={{width: "80%", height: 25, borderRadius: 10}}/>
                                    </Grid>
                                    <Divider orientation="vertical"/>
                                    <Grid container item justifyContent="center" xs={2}>
                                        <Skeleton variant="rectangular"
                                                  sx={{width: "80%", height: 25, borderRadius: 10}}/>
                                    </Grid>
                                    <Divider orientation="vertical"/>
                                    <Grid container item justifyContent="center" xs={2.8}>
                                        <Skeleton variant="rectangular"
                                                  sx={{width: "80%", height: 25, borderRadius: 10}}/>
                                    </Grid>
                                </Grid>

                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Hidden>
            <Hidden lgUp smDown>
                <Grid container item xs={12} rowSpacing={2} >
                    {[...Array(8)].map(i => (
                        <Grid container item xs={12} spacing={2}>
                           <Paper component={Grid} container item sx={{width : "100%", padding : 4,height : 130}} justifyContent={"center"} elevation={8} spacing={2}>
                               <Grid container item xs={1} alignItems="center">
                                   <Skeleton variant="rectangular" sx={{width: "100%", height: 50, borderRadius: 2}}/>
                               </Grid>
                               <Grid container item xs={2} alignItems="center">
                                   <Skeleton variant="rectangular" sx={{width: "100%", height: 50, borderRadius: 2}}/>

                               </Grid>
                               <Grid container item xs={3} justifyContent="center" alignItems="center">
                                   <Skeleton variant="rectangular" sx={{width: "100%", height: 50, borderRadius: 2}}/>

                               </Grid>
                               <Grid container item xs={2} justifyContent="center" alignItems="center">
                                   <Skeleton variant="rectangular" sx={{width: "100%", height: 50, borderRadius: 2}}/>

                               </Grid>
                               <Grid container item xs={4} justifyContent="center" alignItems="center">
                                   <Skeleton variant="rectangular" sx={{width: "100%", height: 50, borderRadius: 2}}/>
                               </Grid>
                           </Paper>
                        </Grid>

                    ))}
                </Grid>
            </Hidden>
            <Hidden smUp>
                <Grid container item xs={12} rowSpacing={2}>
                    {[...Array(8)].map(i => (
                        <Grid container item xs={12} alignItems="center">
                            <Paper elevation={20} style={{
                                width: "100%",
                                height: 150,
                                padding: 20,
                                borderTop: (t) => `solid ${t.palette.primary.main}`
                            }}>
                                <Grid container item xs={12} spacing={2} justifyItems="center" alignItems="center">
                                    <Grid container item xs={2} alignContent="center" alignItems="center">
                                        <Skeleton variant="rectangular" sx={{width: "100%", height: 50, borderRadius: 2}}/>
                                    </Grid>
                                    <Grid container item xs={10}>
                                        <Grid container item xs={12} alignItems="center" spacing={2}>
                                            <Grid container item xs={12}>
                                                <Stack style={{width: "100%"}} alignItems="center" direction={"row"}
                                                       spacing={1}>
                                                    <Skeleton variant="rectangular"
                                                              sx={{width: "100%", height: 25, borderRadius: 1}}/>
                                                </Stack>
                                            </Grid>
                                            <Grid container item xs={3} justifyContent="center">
                                                <Skeleton variant="rectangular"
                                                          sx={{width: "100%", height: 25, borderRadius: 1}}/>
                                            </Grid>
                                            <Grid container item xs={9} justifyContent="center">
                                                <Skeleton variant="rectangular"
                                                          sx={{width: "100%", height: 25, borderRadius: 1}}/>
                                            </Grid>
                                            <Grid container item xs={6} justifyContent="center">
                                                <Skeleton variant="rectangular"
                                                          sx={{width: "80%", height: 25, borderRadius: 1}}/>
                                            </Grid>
                                            <Grid container item xs={6} justifyContent="center">
                                                <Skeleton variant="rectangular"
                                                          sx={{width: "80%", height: 25, borderRadius: 1}}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Hidden>
        </>
    )
}