import React from "react";
import Stack from "@mui/material/Stack";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {Tooltip} from "@mui/material";

export default function OrderInfos({order,direction  = "row"}){

    return(
        <Stack direction={direction} spacing={1} alignItems="center"  sx={{fontSize : 20}}>
            <Tooltip  title={order.free_delivery ? 'Livraison gratuite' : "livraison payante"}>
                <LocalShippingIcon sx={{cursor : "pointer"}} color={order.free_delivery ? 'primary' : "success" }/>
            </Tooltip>
            <Tooltip  title={order.paymentStatus === 2 ? 'Recevoir paiement' : "payé"}>
                {order.paymentStatus === 2 ? <MoneyOffIcon sx={{cursor : "pointer"}}/> : <MonetizationOnIcon sx={{cursor : "pointer"}}/>}
            </Tooltip>
            <Tooltip  title={order.moneyReceived === 0  ? 'Argent non collécté' : "Argent collécté"}>
                <CreditScoreIcon  color={order.moneyReceived === 0 ? "primary" : "secondary"} sx={{fontSize : 20,cursor : "pointer"}}/>
            </Tooltip>

            {order.isExchange === 1 &&
                <Tooltip  title="C'est un echange">
                    <ChangeCircleIcon sx={{cursor : "pointer"}} color={"info"}/>
                </Tooltip>

            }
        </Stack>
    )
}