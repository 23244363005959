import React, {useContext, useEffect, useState} from 'react';
import * as yup from 'yup';
import {useFormik} from "formik";
import {Grid, Typography} from "@mui/material";
import UserContext from "../../../context/userContext";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DropZonePreview from "../../../component/dropZone";
import AlertsContext from "../../../context/alertsContext";
import apiCall from "../../../api/axios_config";
import {useParams} from "react-router";
import Loader from "../../../component/common/loader";
import {apiUrl} from "../../../common";
import Image from "mui-image";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {useHistory} from "react-router-dom";

export default function EditProduct({setSnackbar}) {
    const {id} = useParams();
    const {accessToken} = useContext(UserContext);
    const {setProgressBar, setSnackBar} = useContext(AlertsContext);
    const [product, setProduct] = useState({name: "ds"});
    const [isLoading, setIsLoading] = useState(true);
    const [uploaded, setUploaded] = useState(false);
    const history = useHistory();
    const validationSchema = yup.object({
        name: yup.string().required("Le nom de produit est obligatoire"),
        cost: yup.number().required("le coût de la produit est obligatoire"),
        description: yup.string().required("la description est obligatoire"),
        price: yup.number().required("le prix du vente de la produit est obligatoire")
    });

    useEffect(() => {
        apiCall(accessToken).get(`/products/single/${id}`)
            .then(res => {
                setProduct(res.data.product)
                formik.setFieldValue('name', res.data.product.name)
                formik.setFieldValue('cost', res.data.product.cost)
                formik.setFieldValue('price', parseInt(res.data.product.price).toFixed(0))
                formik.setFieldValue('description', res.data.product.description)
            })
            .catch((e) => {
                if(e.response.status === 404) history.push('/404')
            })
            .finally(() => setIsLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            cost: "",
            description: "",
            price: "",
            files: [],
        },
        onSubmit: (values) => {
            setProgressBar(true);
            let formData = new FormData();

            formData.append("name", values.name);
            formData.append("price", values.price);
            formData.append("cost", values.cost);
            formData.append("description", values.description);
            for (let i = 0; i < values.files.length; i++) {
                formData.append("files[]", values.files[i]);
            }
            apiCall(accessToken).post(`products/update/${id}`, formData)
                .then((res) =>{
                    setProduct(res.data.product)


                })
                .catch((e) => {
                })
                .finally(() => {
                    setProgressBar(false)
                    setSnackBar({
                        message : "Produit modifier avec succes",
                        severity : 'success',
                        status : true
                    });
                    setUploaded(prevState => !prevState);

                })
        }
    });

    function setFilesToFormik(files) {
        formik.setFieldValue('files', files);
    }


    return (
        isLoading ?
            <Loader/>
            :
            <Grid container rowSpacing={2} columnSpacing={2} xs={12}>
                <Grid container item xs={12}>
                    <Typography variant={"h6"}>
                        Nouveau Produits :
                    </Typography>
                </Grid>
                <Grid container item md={6} xs={12}>
                    <TextField
                        fullWidth
                        id="name"
                        label="Nom de la produit"
                        type="text"
                        variant="outlined"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.errors.name}
                    />
                </Grid>
                <Grid container item md={3} xs={12}>
                    <TextField
                        fullWidth
                        id="cost"
                        label="Coût de la produit"
                        type="text"
                        variant="outlined"
                        value={formik.values.cost}
                        onChange={formik.handleChange}
                        error={formik.touched.cost && Boolean(formik.errors.cost)}
                        helperText={formik.errors.cost}
                    />
                </Grid>
                <Grid container item md={3} xs={12}>
                    <TextField
                        fullWidth
                        id="price"
                        label="Prix de la produit"
                        type="text"
                        variant="outlined"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        helperText={formik.errors.price}
                    />
                </Grid>
                <Grid container item md={12} xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        minRows={2}
                        id="description"
                        label="Description de la produit"
                        type="textarea"
                        variant="outlined"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.errors.description}
                    />
                </Grid>
                <Grid container item md={12} xs={12}>
                    <DropZonePreview uploaded={uploaded} setFiles={(files) => setFilesToFormik(files)}/>
                </Grid>
                <Grid container item xs={12}>
                    {product.images.map((image) => (
                        <Grid container item xs={3} key={image.image}>
                            <DeleteForeverIcon
                                sx={{
                                    position: "relative",
                                    zIndex: 9999, top: 5,
                                    left: 30,
                                    color: (t) => t.palette.primary.main,
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: (t) => t.palette.primary.dark
                                    }

                                }}

                                onClick={() => {
                                    setProgressBar(true);

                                    apiCall(accessToken).post(`products/delete_image`,{image : image.image,product_id : product.id})
                                        .then((res) => {
                                            setProduct(res.data.product)
                                            setSnackBar({
                                                message : res.data.message,
                                                severity : 'success',
                                                status : true
                                            })
                                        })
                                        .catch((e) => {
                                            setSnackBar({
                                                message : "something went wrong please retry again",
                                                severity : 'error',
                                                status : true
                                            })
                                            }
                                        )
                                        .finally(() => {
                                            setProgressBar(false);
                                            setUploaded(prevState => !prevState);
                                        })
                                }}
                            />
                            <Image alt={image.image} width={230} src={`${apiUrl}images/products/${image.image}`}/>
                        </Grid>
                    ))}
                </Grid>
                <Grid container justifyContent={"center"} item md={12} xs={12}>
                    <Button size={"large"} variant={"contained"} color={"primary"}
                            onClick={formik.submitForm}>Ajouter</Button>
                </Grid>
            </Grid>
    );
}
