import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {DashboardLayout} from "../../layout/v2/dashboard_layout";
import UserContext from "../../context/userContext";

const RestrictedToAdminRoute = ({component: Component, ...rest}) => {

    const {isAuth,userRole} = useContext(UserContext);
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /login page
        <Route  {...rest} render={(props) => (
            isAuth && userRole === "admin"?
                <DashboardLayout>
                    <Component {...props} />
                </DashboardLayout>
                :
                <Redirect to="/no_access" />
        )} />
    );
};

export default RestrictedToAdminRoute;