import React from "react";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Grid from "@mui/material/Grid";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
    root : {
      height: "100vh"
    },
    logo : {
        width : 160,
        height : 200
    }
});

export default function Loader() {
    const classes = useStyles();
    return(
        <Grid
            container
            item
            className={classes.root}
            direction={"column"}
            justifyContent="center"
            alignItems="center"
            rowSpacing={8}
        >
            <Grid container justifyContent="center" item>
                <img alt={""} className={classes.logo} src="/logo.png"/>
            </Grid>
            <Grid container justifyContent="center" item>
                <CircularProgress/>
            </Grid>

        </Grid>
    )
}