import React from "react";
import Grid from "@mui/material/Grid";
import {Paper, Skeleton} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DescriptionIcon from '@mui/icons-material/Description';


export default function DeliveredOrderStats(props) {

    const {isLoading, data} = props;
    return (
        <Grid container item xs={12}>
            <Paper
                elevation={8}
                sx={{
                    width: "100%",
                    padding: 4,
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: (t) => t.palette.primary.main,
                        color: "white"
                    }
                }}
            >
                <Grid container item xs={12} justifyContent="center" spacing={2}>
                    <Grid container item xs={12} justifyContent="center" spacing={2}>
                        <Stack alignItems="center" spacing={2}>
                            <Typography fontWeight="bolder" variant="h6">Commande livrés</Typography>
                            <DescriptionIcon
                                sx={{
                                    fontSize: 54
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid container item justifyContent="space-evenly" xs={6} spacing={2}>
                        <Grid container item justifyContent="center" xs={12}>
                            <Typography fontWeight="bolder" variant="h6">Ce Mois</Typography>
                        </Grid>
                        {isLoading ?
                            <Skeleton variant="rectangular" sx={{width: "100%", height: 25, borderRadius: 2}}/>

                            :
                            <Grid container item justifyContent="center" xs={12}>
                                <Typography fontWeight="bolder" variant="h6">{data.count}</Typography>
                            </Grid>
                        }
                    </Grid>

                        <Grid container item justifyContent="space-evenly" xs={6} spacing={2}>
                            <Grid container item justifyContent="center" xs={12}>
                                <Typography fontWeight="bolder" variant="h6">Total</Typography>
                            </Grid>
                            {isLoading ?
                                <Skeleton variant="rectangular" sx={{width: "100%", height: 25, borderRadius: 2}}/>

                                :
                                <Grid container item justifyContent="center" xs={12}>
                                    <Typography fontWeight="bolder" variant="h6">{data.totalOrders}</Typography>
                                </Grid>
                            }
                        </Grid>

                </Grid>
            </Paper>
        </Grid>
    )
}