import React from "react";
import {FormControl, InputLabel, MenuItem, Paper, Select} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';


export default function PickUp({stores,formik,deliveryCompanies,handleStoreChange}){

    return(
        <Paper sx={{width: "100%", padding: 2}}>
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12}>
                    <Typography variant={"body1"}>
                        PickUp :
                    </Typography>
                </Grid>
                <Grid container item xs={8}>
                    <FormControl fullWidth>
                        <InputLabel shrink={true} id="magasin">Magasin</InputLabel>
                        <Select
                            name="order.store_id"
                            value={formik.values.order.store_id}
                            label="Magasin"
                            onChange={(v) => handleStoreChange(v.target.value)}
                        >

                            {stores && stores.map(store => {
                                return (
                                    <MenuItem key={`store-${store.id}`} value={store.id}>{store.name}</MenuItem>
                                )
                            })}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel shrink={true} id="magasin">Société de Livraison</InputLabel>
                        <Select
                            name="order.deliveryCompany"
                            value={formik.values.order.deliveryCompany}
                            label="Société de Livraison"
                            onChange={formik.handleChange}
                        >

                            {deliveryCompanies && deliveryCompanies.map(company => {
                                return (
                                    <MenuItem selected={company.id === 7} key={`store-${company.id}`} value={company}>{company.name}</MenuItem>
                                )
                            })}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item xs={12}>
                    <Stack direction="row" spacing={1} xs={12}>
                        <MapsHomeWorkIcon color={"primary"}/>
                        <Typography fontWeight={700}>{stores.find((store) => (store.id === formik.values.order.store_id)).address}</Typography>
                    </Stack>
                </Grid>

            </Grid>
        </Paper>

    )
}