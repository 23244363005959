import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {apiUrl} from "../../../common";
import UserContext from "../../../context/userContext";
import {headers} from "../../../api/common";
import Loader from "../../../component/common/loader";
import Grid from "@mui/material/Grid";
import Image from "mui-image";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import {useHistory} from "react-router";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// import {deleteProduct} from "../../../api/products";
import DeleteProduct from "./deleteProduct";
import {routesName} from "../../../router/router";


const useStyles = makeStyles((theme) => ({
    span : {
        color : theme.palette.primary.main,
        fontWeight : 900,
    }
}));

export default function ProductsList() {
    const [products,setProducts] = useState({});
    const [loader, setLoader] = useState(true);
    const {accessToken} = useContext(UserContext);
    const classes = useStyles();
    const history = useHistory();

    // function remove(productID){
    //     deleteProduct(accessToken,productID).then((res) => {});
    // }
    function newProductsList(newList){
        setProducts(newList);
    }
    useEffect(() => {
        axios.get(
            apiUrl + "manager/products/all",
            {headers : headers(accessToken)}
        )
            .then((res) => {
                setProducts(res.data.products);
                setLoader(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return(
        loader ?
            <Loader/>

            :
            <Grid container item rowSpacing={3} columnSpacing={2} xs={12}>
                <Grid container item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link sx={{cursor : "pointer"}} underline="hover" color="inherit" onClick={() => history.push('/')}>
                            Dashboard
                        </Link>
                        <Typography color="text.primary">Liste des produits</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid container  item xs={12}>
                    <Typography
                        sx={{
                            color : (theme) => theme.palette.primary.main,
                            borderRadius : 2,
                            fontWeight : 700
                        }}
                        variant={"h6"}
                    >
                        Liste des produits :
                    </Typography>
                </Grid>
                <Grid   container columnSpacing={2} rowSpacing={4} item xs={12}>
                    {
                        products.map((product) => {
                            return(
                                <Grid container rowSpacing={2}  item xs={12} md={6} lg={4}  >
                                    <Grid sx={{ height : 250}} container item xs={12}>
                                        <Image
                                            src={`${apiUrl}images/products/${product.images[0] ? product.images[0].image : "placeholder.jpg" }`}
                                            fit="cover"
                                            easing="ease"
                                            width="100%"
                                        />
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Typography variant="body1">{product.name}</Typography>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Grid container item xs={6}>
                                            <Typography><span className={classes.span}>Coût :</span> {product.cost}</Typography>
                                        </Grid>
                                        <Grid container item xs={6}>
                                            <Typography><span className={classes.span}>Prix:</span> {product.price}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Accordion sx={{width : "100%"}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>Description :</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    {product.description}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid  container item  xs={12}>
                                        <Stack spacing={2} direction="row">
                                            <Button variant="outlined" onClick={() => history.push(routesName.products.edit(product.id))}>Editer</Button>
                                            <DeleteProduct setProducts={(newList) => newProductsList(newList)} product={product}/>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>

    )
}