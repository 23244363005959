import React from "react";
import Grid from "@mui/material/Grid";
import {Paper, Skeleton} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PaidIcon from '@mui/icons-material/Paid';


export default function ReturnedOrders(props) {

    const {isLoading, data} = props;

    // const [sum,setSum] = useState(0);
    // ReturnStatus[8,14,16,17,12]
    function returnThisMonths(data) {
        const returnStatus = [16,17,12]
        const returnedOrder = data.filter((order) => {
            if (returnStatus.includes(order.deliveryStatus)) return order
            else return false
        }).length

        const percentage = returnedOrder / data.length * 100

        return `${returnedOrder} ( ${Math.round(percentage * 100) / 100} %)`
    }

    return (
        <Grid container item xs={12}>
            <Paper
                elevation={8}
                sx={{
                    width: "100%",
                    padding: 4,
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: (t) => t.palette.primary.main,
                        color: "white"
                    }
                }}
            >
                <Grid container item xs={12} justifyContent="center" spacing={2}>
                    <Grid container item xs={12} justifyContent="center" spacing={2}>
                        <Stack alignItems="center" spacing={2}>
                            <Typography fontWeight="bolder" variant="h6">Colis en retour</Typography>
                            <PaidIcon
                                sx={{
                                    fontSize: 54
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid container item justifyContent="space-evenly" xs={6} spacing={2}>
                        <Grid container item justifyContent="center" xs={12}>
                            <Typography fontWeight="bolder" variant="h6">Ce Mois</Typography>
                        </Grid>
                        {isLoading ?
                            <Skeleton variant="rectangular" sx={{width: "100%", height: 25, borderRadius: 2}}/>

                            :
                            <Grid container item justifyContent="center" xs={12}>
                                <Typography fontWeight="bolder"
                                            variant="h6">{returnThisMonths(data.orderOfMonth)}</Typography>
                            </Grid>
                        }
                    </Grid>

                    <Grid container item justifyContent="space-evenly" xs={6} spacing={2}>
                        <Grid container item justifyContent="center" xs={12}>
                            <Typography fontWeight="bolder" variant="h6">Total</Typography>
                        </Grid>
                        {isLoading ?
                            <Skeleton variant="rectangular" sx={{width: "100%", height: 25, borderRadius: 2}}/>

                            :
                            <Grid container item justifyContent="center" xs={12}>
                                <Typography fontWeight="bolder"
                                            variant="h6">{returnThisMonths(data.allOrders)}</Typography>
                            </Grid>
                        }
                    </Grid>

                </Grid>
            </Paper>
        </Grid>
    )
}