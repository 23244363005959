import React from "react";
import {FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography} from "@mui/material";


export default function DiscountSection({formik}){

    return(
        <Paper sx={{width: "100%", padding: 2}}>
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12}>
                    <Typography variant={"body1"}>
                        Special:
                    </Typography>
                </Grid>
                <Grid container item spacing={2} md={12} xs={12}>
                    <Grid container item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel shrink={true} id="magasin">Remise :</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                name="order.discount"
                                value={formik.values.order.discount}
                                label="Remise :"
                                onChange={formik.handleChange}
                            >
                                <MenuItem value={0}>0%</MenuItem>
                                <MenuItem value={5}>5%</MenuItem>
                                <MenuItem value={10}>10%</MenuItem>
                                <MenuItem value={15}>15%</MenuItem>
                                <MenuItem value={20}>20%</MenuItem>
                                <MenuItem value={25}>25%</MenuItem>
                                <MenuItem value={30}>30%</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>

    )
}