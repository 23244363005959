import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import apiCall from "../../../../api/axios_config";
import UserContext from "../../../../context/userContext";
import {useParams} from "react-router";
import {Paper, Skeleton} from "@mui/material";
import SortButton from "../components/sortButton";
import Typography from "@mui/material/Typography";


export default function StockStatus({storeId = null}) {
    const {id} = useParams();
    const [stock, setStock] = useState({});
    const [isLoding, setLoading] = useState(true);
    const {accessToken} = useContext(UserContext);
    const [currentFilter , setCurrentFilter] = useState("dispoASC");


    useEffect(() => {
        const url = id ? `/stores/get_stock/${id}` : `/stores/get_stock/${storeId}`
        apiCall(accessToken).get(url)
            .then((res) => {
                const toSort = [].concat(res.data.stock).sort((a,b) => a.quantity - a.sales > b.quantity - b.sales ? 1 : -1);

                setStock(toSort);
            })
            .catch((e) => {
            })
            .finally(() => {
                setLoading(false);

            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setFiltredStock (sorted,filter) {
        setStock(sorted);
        setCurrentFilter(filter);
    }
    return (

        <Grid container item xs={12} columnSpacing={2}>
            <Grid container item xs={12} justifyContent={"flex-end"}>
                    <SortButton stock={stock} currentFilter={currentFilter} setFiltredStock={setFiltredStock}/>
            </Grid>
            <Grid container item xs={12} sx={{paddingLeft: '0px !important', paddingTop: "0 !important"}}>
                <Paper
                    component={Grid}
                    container
                    item
                    xs={12}
                    elevation={8}
                    sx={{
                        width: '100%',
                        height: '100%',
                        padding: 1,
                        backgroundColor: t => t.palette.primary.main,
                        color: "white"
                    }}
                >

                    <Grid container item xs={4} sx={{borderRight: '1px solid white'}}>
                        Produit
                    </Grid>

                    <Grid container item xs={3} justifyContent="center" sx={{borderRight: '1px solid white'}}>
                        Disponible
                    </Grid>
                    <Grid container item xs={3} justifyContent="center" sx={{borderRight: '1px solid white'}}>
                        QTE Total
                    </Grid>
                    <Grid container item xs={2} justifyContent="center">
                        Vente
                    </Grid>
                </Paper>
            </Grid>

            {
                isLoding ?
                    <Grid container item xs={12} >
                        {
                            [...Array(8)].map((n,index) => (
                                <Grid container item xs={12} sx={{paddingLeft: '0px !important', paddingTop: "0 !important", marginTop: 4}}>
                                    <Paper
                                        component={Grid}
                                        container
                                        item
                                        xs={12}
                                        elevation={8}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            padding: 1,
                                        }}
                                    >

                                        <Grid container item xs={4} sx={{borderRight: '1px solid red'}}>
                                            <Skeleton variant="rectangular" sx={{width: "70%", borderRadius: 2}}/>
                                        </Grid>

                                        <Grid container item xs={3} justifyContent="center" sx={{borderRight: '1px solid red'}}>
                                            <Skeleton variant="rectangular" sx={{width: "70%", borderRadius: 2}}/>
                                        </Grid>
                                        <Grid container item xs={3} justifyContent="center" sx={{borderRight: '1px solid red'}}>
                                            <Skeleton variant="rectangular" sx={{width: "70%", borderRadius: 2}}/>
                                        </Grid>
                                        <Grid container item xs={2} justifyContent="center">
                                            <Skeleton variant="rectangular" sx={{width: "70%", borderRadius: 2}}/>
                                        </Grid>
                                    </Paper>
                                </Grid>

                            ))
                        }
                    </Grid>
                    :
                    stock.map((item, index) => (
                        <Grid key={`item.name-${index}`} container item xs={12}
                              sx={{paddingLeft: '0px !important', paddingTop: "0 !important", marginTop: 4}}>
                            <Paper
                                component={Grid}
                                container
                                item
                                xs={12}
                                elevation={8}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 1,
                                }}
                            >

                                <Grid container item xs={4} align sx={{borderRight: '1px solid red'}}>
                                    <Typography fontWeight={900}>{item.name}</Typography>
                                </Grid>

                                <Grid container item xs={3} alignItems="center" justifyContent="center"
                                      sx={{borderRight: '1px solid red'}}>
                                    <Typography
                                        fontWeight={900}
                                        fontSize={17}
                                        color={item.quantity - item.sales < 10 ? "primary" : "secondary"}
                                    >
                                        {item.quantity - item.sales}
                                    </Typography>


                                </Grid>
                                <Grid container item xs={3} alignItems="center" justifyContent="center"
                                      sx={{borderRight: '1px solid red'}}>
                                    {item.quantity}
                                </Grid>
                                <Grid container item xs={2} alignItems="center" justifyContent="center">
                                    {item.sales}
                                </Grid>
                            </Paper>
                        </Grid>
                    ))

            }
        </Grid>
    )
}