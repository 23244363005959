import React from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import moment from "moment";
import OrderInfos from "./components/infos";
import DeliveryStatus from "./components/delivery_status";
import OrderActions from "./actions";
import SelectOrder from "./components/selectOrder";


export default function DesktopTable({orders}) {

    return (
        <Grid container item xs={12} rowSpacing={1}>
            {orders.map((order,index) => (
                <Grid container  item xs={12}>
                    <Paper  sx={{width: "100%"}}>
                        <Grid container columnSpacing={1} item sx={{height : "100px",padding : 2}} xs={12} alignItems="center">
                            <Grid container item justifyContent="center" xs={1}>
                                <SelectOrder order={order}/>
                            </Grid>
                            <Divider orientation="vertical"  />
                            <Grid container item justifyContent="center" xs={1}>
                                <Stack alignItems="center">
                                    <Typography fontWeight={500} fontSize={13}>{order.commandeNumber}</Typography>
                                    <Typography fontSize={12} fontWeight={400} color="primary">{moment(order.deliveryDate).format('D-MM-Y')}</Typography>
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical"  />
                            <Grid container item justifyContent="center" xs={2}>
                                <Stack alignItems="center">
                                    <Typography noWrap>{order.client.fullName}</Typography>
                                    <Typography sx={{fontSize : 13}} color="primary">{order.client.phone}  {order.client.phoneTwo && "- " + order.client.phoneTwo}</Typography>
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical"  />
                            <Grid container item justifyContent="center" xs={1}>
                                <Typography >{order.amount} DT</Typography>
                            </Grid>
                            <Divider orientation="vertical"  />
                            <Grid container item justifyContent="center" xs={2}>
                                <OrderInfos order={order}/>
                            </Grid>
                            <Divider orientation="vertical"  />
                            <Grid container item justifyContent="center" xs={2}>
                                <Stack alignItems="center" spacing={1}>
                                    <DeliveryStatus order={order}/>
                                    <Typography NoWrap={true} fontWeight="bolder" align="center" variant="body2">
                                        {order.client.delegation.gov.name} - {order.client.delegation.name}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Divider orientation="vertical"  />
                            <Grid container item justifyContent="center" xs={2.8}>
                                <OrderActions order={order}/>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
            ))}

        </Grid>
    )
}