import React from "react";
import Grid from "@mui/material/Grid";
import OrderReturn from "./component/orderReturn";
import Filling from "../manager/stores/sub_page/filling";
import CustomPaper from "../../component/common/custom_paper";
import Typography from "@mui/material/Typography";


export default function MagDashboard(props){

    const {store} = props;
    return(
        <Grid container item xs={12} spacing={2}>
            <Grid container item xs={12} md={6}>
                <OrderReturn store={store}/>
            </Grid>
            <Grid container item xs={12} md={6}>
                <CustomPaper>
                    <Grid container item xs={12}>
                        <Typography fontWeight={600} variant={"h6"}>
                            Historique de stockage :
                        </Typography>
                    </Grid>
                    <Filling css={{maxHeight : 800, overflow : "auto"}} storeId={1}/>
                </CustomPaper>
            </Grid>
        </Grid>
    )
}