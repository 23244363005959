import React from "react";
import Grid from "@mui/material/Grid";
import OrderAccordion from "./components/order_accordion";


export default function TabletTable({orders}){

    return(
        <Grid container item  xs={12} spacing={2}>
            {orders.map((order) => (
                <OrderAccordion order={order}/>
            ))}
        </Grid>
    )
}