import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import apiCall from "../../../api/axios_config";
import UserContext from "../../../context/userContext";
import {useParams} from "react-router";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Loader from "../../../component/common/loader";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TabsLayout from "../../../layout/tabLayout";
import StockStatus from "./sub_page/stock_status";
import Filling from "./sub_page/filling";
import {useHistory} from "react-router-dom";


export default function SingleStore() {
    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [store, setStore] = useState({});
    const {accessToken} = useContext(UserContext);
    const history = useHistory();
    useEffect(() => {
        apiCall(accessToken)
            .get(`/stores/single/${id}`)
            .then((res) => setStore(res.data.store))
            .catch(e => {
                if(e.response.status === 404) history.push('/404')
            })
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Grid container item xs={12} justifyContent="center" rowSpacing={2}>
            {loading ? <Loader/> :
                <Grid Container item xs={12}>
                    <Paper sx={{width: "100%", padding: 4}}>
                        <Stack direction="row" spacing={4} justifyContent="space-around" alignItems="center">
                            <Stack>
                                <Typography color={"primary"} typography={"h5"}>Boutique :</Typography>
                                <Typography fontWeight={600} typography={"h5"}>{store.name}</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                                <LocationOnIcon sx={{fontSize: 40}}/>
                                <Typography fontWeight={600} typography={"h6"}>{store.address}</Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
            }

            <Grid Container item xs={12} >
                <Paper sx={{width: "100%"}}>
                    <TabsLayout
                        tabs={[
                            {
                                label : "Etat de stock",
                                index : 0,
                                component : <StockStatus/>
                            },
                            {
                                label : "stockage",
                                index : 1,
                                component:  <Filling/>
                            }
                        ]}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}