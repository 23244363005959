import * as React from 'react';
import {useContext} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import axios from "axios";
import {apiUrl} from "../../../common";
import UserContext from "../../../context/userContext";
import Button from "@mui/material/Button";
import AlertsContext from "../../../context/alertsContext";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteProduct(props) {
    const [open, setOpen] = React.useState(false);
    const {accessToken} = useContext(UserContext);
    const {setSnackBar} = useContext(AlertsContext);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    function deleteProduct(){
        axios.delete(
            apiUrl + "manager/products/delete/" + props.product.id,
            {
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + accessToken
                }
            }
        ).then((response) => {
            handleClose();
            props.setProducts(response.data.newProductsList);
            setSnackBar({
                status : true,
                message : response.data.message,
                severity : "success"
            });

        }).catch((error) => {
        })
    }
    return (
        <div>
            <Button  variant={"contained"} color={"primary"} onClick={handleClickOpen}>
                Supprimer
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                fullWidth
                maxWidth={"sm"}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Voulez vous vraiment supprimer</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {props.product.name}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleClose}>Annuler</Button>
                    <Button variant={"contained"} color={"primary"} onClick={deleteProduct}>Confirmer</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
