import React, {useContext} from 'react';
import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DashboardNavbar} from './dashboard-navbar';
import {DashboardSidebar} from './dashboard-sidebar';
import Container from "@mui/material/Container";
import SideBarContext from "../../context/sideBarContext";

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 280
    }
}));

export const DashboardLayout = (props) => {
    const { children } = props;
    const {isSidebarOpen , setSidebarOpen} = useContext(SideBarContext);
    return (
        <>
            <DashboardLayoutRoot>


                <Box
                    sx={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        width: '100%',
                        pt : 2,
                        pb : 2
                    }}
                >
                    <Container maxWidth={"lg"}>
                        {children}
                    </Container>
                </Box>
            </DashboardLayoutRoot>
            {/*<DashboardNavbar issidebarOpen={isSidebarOpen} onSidebarOpen={() => setSidebarOpen(true)} />*/}
            <DashboardNavbar  onSidebarOpen={() => setSidebarOpen(true)} />
            <DashboardSidebar
                onClose={() => setSidebarOpen(false)}
                open={isSidebarOpen}
            />
        </>
    );
};