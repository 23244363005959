import React, {useContext} from "react";
import EditIcon from '@mui/icons-material/Edit';
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DownloadingIcon from '@mui/icons-material/Downloading';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {useHistory} from "react-router-dom";
import {Tooltip} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ApproveAction from "../../../../component/common/approveAction";
import apiCall from "../../../../api/axios_config";
import UserContext from "../../../../context/userContext";
import OrdersContext from "../../../../context/OrderContext";
import AlertsContext from "../../../../context/alertsContext";


export default function OrderActions({order}){
    const history = useHistory();
    const {accessToken} = useContext(UserContext);
    const {orders,setOrders} = useContext(OrdersContext);
    const {setSnackBar} = useContext(AlertsContext);
    function deleteOrder(orderId) {
        apiCall(accessToken).post('/orders/delete/' + orderId)
            .then((res) => {
                let newList = orders.filter(order => { return order.id !== orderId});
                setOrders(newList);
                setSnackBar({
                    status : true,
                    message : res.data.message,
                    severity: "success"
                });
            }).catch((error) => setSnackBar({status: true, message: error.data.message, severity : "error"}) );

    }
    return(
        <Stack direction="row" spacing={1}>
            <IconButton
                disabled={order.nid !== null}
                sx={{
                    width : 35,
                    height :35,
                    color : "white",
                    backgroundColor : (t) => t.palette.success.main,
                    "&:hover" : {
                        backgroundColor : (t) => t.palette.success.light
                    }
                }}
                onClick={() => history.push(`/orders/edit/${order.id}`)}
                variant="filled"
                color="secondary"
            >
                <Tooltip title={"Editer commande"}>
                    <EditIcon sx={{fontSize : 20}}/>
                </Tooltip>
            </IconButton>
            <IconButton
                sx={{
                    width : 35,
                    height :35,
                    color : "white",
                    backgroundColor : (t) => t.palette.success.main,
                    "&:hover" : {
                        backgroundColor : (t) => t.palette.success.light
                    }
                }}
                variant="filled"
                color="secondary"
                onClick={() => window.open(`/orders/pdf/${order.id}`, "_blank")}
            >
                <Tooltip title={"Download Bon de commade"}>
                    <DownloadingIcon sx={{fontSize : 25}}/>
                </Tooltip>
            </IconButton>
            <IconButton
                sx={{
                    width : 35,
                    height :35,
                    color : "white",
                    backgroundColor : (t) => t.palette.success.main,
                    "&:hover" : {
                        backgroundColor : (t) => t.palette.success.light
                    }
                }}
                variant="filled"
                color="secondary"
                disabled={!order.nid}
                onClick={() => window.open(`https://partner-app.intigo.tn/api/v1/externalapi/bordereau/${order.nid}`,'_blank')}
            >
                <Tooltip title={"Download bordereau"}>
                    <PictureAsPdfIcon sx={{fontSize : 25}}/>
                </Tooltip>
            </IconButton>

           <ApproveAction
            approveAction={() => deleteOrder(order.id)} title={"voulez vous varaiment supprimer cette commandes ?"}
           >
               <IconButton
                   sx={{
                       width : 35,
                       height :35,
                       color : "white",
                       backgroundColor : (t) => t.palette.primary.main,
                       "&:hover" : {
                           backgroundColor : (t) => t.palette.primary.dark
                       }
                   }}
                   variant="filled"
                   disabled={order.nid}
                   onClick={() => console.log("hello")}
               >
                   <Tooltip title={"DELETE"}>
                       <DeleteIcon sx={{fontSize : 25}}/>
                   </Tooltip>
               </IconButton>
           </ApproveAction>

        </Stack>
    )
}