import React, {useContext} from "react";
import {Checkbox} from "@mui/material";
import SelectedOrderContext from "../../../../../context/selectedOrderContext";


export default function SelectOrder({order}){

    const {selectedOrder,handleSelectedOrders} = useContext(SelectedOrderContext);
    const isSelected = selectedOrder && !!selectedOrder.find((item) => item.id === order.id)
    return(
        <Checkbox
            checked={isSelected}
            onClick={() => {
                handleSelectedOrders(order)
            }}
        />
    )
}