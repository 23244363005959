import React, {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import UserContext from "../../../context/userContext";
import apiCall from "../../../api/axios_config";
import Stack from "@mui/material/Stack";
import StorefrontIcon from '@mui/icons-material/Storefront';
import Loader from "../../../component/common/loader";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useHistory} from "react-router-dom";


export default function StoresIndex(){

    const [stores, setStores] = useState({});
    const {accessToken} = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    useEffect(() => {
        apiCall(accessToken).get('/stores/all').then((res) => setStores(res.data.stores)).finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
            loading ?
                <Loader/>
                :
                <Grid container item xs={12} justifyContent="center" spacing={2}>
                    {stores.map((store) => (
                        <Grid container item xs={4}>
                            <Paper
                                elevation={8}
                                onClick={() => history.push(`/stores/${store.id}`)}
                                sx={{
                                    width : "100%",
                                    padding : 4,
                                    cursor : "pointer",
                                    "&:hover" : {
                                        backgroundColor : (t) => t.palette.primary.main,
                                        color : "white"
                                    }
                            }}
                            >
                                <Stack alignItems="center" spacing={2}>
                                   <Stack alignItems="center">
                                       <StorefrontIcon
                                           sx={{
                                               fontSize : 54
                                           }}
                                       />
                                   </Stack>
                                    <Stack alignItems="center">
                                        <Typography fontWeight="bolder" variant="h6">{store.name}</Typography>
                                        <Typography>{store.address}</Typography>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
    )
}