import React, {useContext, useEffect, useState} from 'react';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import UserContext from "../../../context/userContext";
import apiCall from "../../../api/axios_config";



export default function OrderStats() {

    const [chartData, setChartData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {accessToken} = useContext(UserContext);

    useEffect(() => {
        apiCall(accessToken)
            .get('/orders/sales_chart')
            .then((res) => setChartData(res.data))
            .catch((e) => {
            })
            .finally(() => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
            !isLoading &&
                    <ResponsiveContainer width="100%" >
                        <LineChart  data={chartData}
                                   margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis
                                label={{value : "Mois" , position : "insideBottom",offset : -3 }}
                                angle={90}
                                height={80}
                                dy={25}
                                dataKey="month_year"
                            />
                            <Tooltip/>
                            {/*<Legend/>*/}
                            <Line strokeWidth={1.5} name="commandes" type="monotone" dataKey="data" stroke="#ed343c"/>
                            <YAxis   label={{value : "QTE", angle: -90 , position : "insideLeft"}} type="number" domain={['auto', 'auto']}/>
                        </LineChart>
                    </ResponsiveContainer>

    )
}