import React, { useContext} from "react";
import OrderStats from "./subPage/orderStats";
import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import DeliveredOrderStats from "./component/deliveredOrderStats";
import MoneyStats from "./component/moneyStats";
import ReturnedOrders from "./component/returnedOrders";
import Typography from "@mui/material/Typography";
import ProductsSales from "./component/productsSales";
import ClientByGov from "./component/ClientByGov";
import {DashboardContext} from "./home";


export default function AdminDashboard(){
    const {orderOftheMonth,isLoading} = useContext(DashboardContext);
    return(
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12} spacing={2}>
                    <Grid container item xs={12} md={4}>
                        <DeliveredOrderStats isLoading={isLoading} data={orderOftheMonth}/>
                    </Grid>
                    <Grid container item xs={12} md={4} sm={6}>
                        <MoneyStats isLoading={isLoading} data={orderOftheMonth}/>
                    </Grid>
                    <Grid container item xs={12} md={4} sm={6}>
                        <ReturnedOrders isLoading={isLoading} data={orderOftheMonth}/>
                    </Grid>


                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid container item xs={12} md={6}>
                        <Grid container item xs={12}>
                            <Paper elevation={8} sx={{width : "100%",padding : 2,color : "white", backgroundColor : (t) => t.palette.primary.main, WebkitBorderBottomLeftRadius : 0,WebkitBorderBottomRightRadius : 0}}>
                                <Typography fontWeight={600}>Stats de commandes | 6 derniers mois</Typography>

                            </Paper>
                        </Grid>
                        <Grid container item xs={12}>
                            <Paper elevation={8} sx={{width : "100%",height : 400,padding : 2, WebkitBorderTopLeftRadius : 0,WebkitBorderTopRightRadius : 0}}>
                                <OrderStats/>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6}>
                        <Grid container item xs={12}>
                            <Paper elevation={8} sx={{width : "100%",padding : 2,color : "white", backgroundColor : (t) => t.palette.primary.main, WebkitBorderBottomLeftRadius : 0,WebkitBorderBottomRightRadius : 0}}>
                                <Typography fontWeight={600}>Top 10 Produit Vendu:</Typography>

                            </Paper>
                        </Grid>
                        <Grid container item xs={12}>
                            <Paper elevation={8} sx={{width : "100%",height : 400,padding : 2, WebkitBorderTopLeftRadius : 0,WebkitBorderTopRightRadius : 0}}>
                                <ProductsSales/>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={12}>
                        <Grid container item xs={12}>
                            <Paper elevation={8} sx={{width : "100%",padding : 2,color : "white", backgroundColor : (t) => t.palette.primary.main, WebkitBorderBottomLeftRadius : 0,WebkitBorderBottomRightRadius : 0}}>
                                <Typography fontWeight={600}>Gouvernorat par nombre de commandes:</Typography>

                            </Paper>
                        </Grid>
                        <Grid container item xs={12}>
                            <Paper elevation={8} sx={{width : "100%",height : 400,padding : 2, WebkitBorderTopLeftRadius : 0,WebkitBorderTopRightRadius : 0}}>
                                <ClientByGov/>
                            </Paper>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
    )
}