import axios from "axios";
import {apiUrl} from "../common";

export function multipartHeaders  (accessToken)   {
    return {
        "Accept": "application/json",
        "Authorization": "Bearer " + accessToken,
        'Content-Type': 'multipart/form-data'
    }
};
export function headers (accessToken)   {
    return {
        "Accept": "application/json",
        "Authorization": "Bearer " + accessToken,
    }
};
export async function getCities(){

    const response = await axios.get(apiUrl + "/places/govs/all?withDeleg=true",
        {
            headers : {
                "Accept" : "application/json",
            }
        }
    )

    return await response.data;
}