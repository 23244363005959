import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import UserContext from "../../context/userContext";

const PublicRoute = ({component: Component, restricted, ...rest}) => {

    const {isAuth} = useContext(UserContext);
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            isAuth && restricted ?
                <Redirect to="/" />
                : <Component {...props} />
        )} />
    );
};

export default PublicRoute;