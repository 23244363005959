import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {useHistory} from "react-router-dom";
import {DashboardLayout} from "../../layout/v2/dashboard_layout";
import Button from "@mui/material/Button";


export default function NoAccess(){
    const history = useHistory();
    return (
        <DashboardLayout>
            <Grid container item xs={12} sx={{height : "80vh"}} justifyContent="center"  alignContent="center"  spacing={2}>
                <Grid container item xs={12} justifyContent="center"  alignContent="center">
                    <img alt="locked" style={{width : "50%"}} src="/no_access-01.png"/>
                </Grid>
                <Grid container item xs={12} justifyContent="center"  alignContent="center">
                    <Stack  spacing={2} alignItems="center" justifyContent="center">
                        <Typography textAlign="center" variant={"h6"}>
                            Désolé, vous n’êtes pas autorisé à accéder à cette page.
                        </Typography>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => history.push('*')}
                        >
                            Retour à l'accueil
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}