import React, {useContext, useEffect, useState} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AddBoxIcon from '@mui/icons-material/AddBox';
import UserContext from "../../context/userContext";
import apiCall from "../../api/axios_config";
import {Paper} from "@mui/material";
import CustomPaper from "../../component/common/custom_paper";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from "moment";
import AlertsContext from "../../context/alertsContext";
import ApproveAction from "../../component/common/approveAction";
import GainsStats from "./subPage/gainsStats";
import Loader from "../../component/common/loader";

export default function Finance() {

    const [expenses, setExpenses] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {accessToken} = useContext(UserContext);
    const {setProgressBar} = useContext(AlertsContext);
    const formik = useFormik({
        validationSchema: yup.object({
            name: yup.string().required('Obligatoire'),
            value: yup.number().min(1, "Zero Dépense non accépté").required('Obligatoire')
        }),
        initialValues: {
            name: "",
            value: "",
        },
        onSubmit: (values, {resetForm}) => {
            setProgressBar(true);
            apiCall(accessToken)
                .post('/finances/expenses/add', values)
                .then((res) => setExpenses((prevState => [res.data.expenses, ...prevState])))
                .catch((e) => {
                })
                .finally(() => {
                    setProgressBar(false);
                    resetForm();
                })
        }
    })


    useEffect(() => {
        apiCall(accessToken)
            .get('/finances/expenses/all')
            .then((res) => setExpenses(res.data.expenses))
            .catch((e) => {
            })
            .finally(() => setIsLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        isLoading ?
            <Loader/>
            :
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12} md={6} alignItems="flex-start">
                    <GainsStats expenses={expenses}/>
                </Grid>
                <Grid container item xs={12} md={6} spacing={2}>
                    <Grid container item xs={12}>
                        <Paper elevation={8} sx={{width: "100%", padding: 2}}>
                            <Grid container item xs={12} spacing={2} sx={{height : "100%"}}>
                                <Grid container item xs={12}>
                                    <Typography variant="h6" color="primary">Dépenses :</Typography>
                                </Grid>
                                <Grid container item xs={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        name="name"
                                        label="Désigniation"
                                        variant="outlined"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </Grid>
                                <Grid container item xs={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        name="value"
                                        label="Valeur (DT)"
                                        variant="outlined"
                                        value={formik.values.value}
                                        onChange={formik.handleChange}
                                        error={formik.touched.value && Boolean(formik.errors.value)}
                                        helperText={formik.touched.value && formik.errors.value}
                                    />
                                </Grid>
                                <Grid container item xs={2} alignItems="center">
                                    <AddBoxIcon
                                        onClick={formik.submitForm}
                                        sx={{
                                            fontSize: 40,
                                            cursor: "pointer",
                                            "&:hover": {
                                                color: t => t.palette.primary.main
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid container item xs={12}>
                        <CustomPaper elevation={8} css={{
                            backgroundColor: t => t.palette.primary.main,
                            color: "white",
                            WebkitBorderBottomLeftRadius: 0,
                            WebkitBorderBottomRightRadius: 0
                        }}>
                            <Grid container item xs={4}>
                                <Typography>Désigniations</Typography>
                            </Grid>
                            <Grid container item xs={3}>
                                <Typography>Valeur(DT)</Typography>
                            </Grid>
                            <Grid container item xs={3}>
                                <Typography>Date</Typography>
                            </Grid>
                            <Grid container item xs={2}>
                            </Grid>
                        </CustomPaper>
                        <CustomPaper elevation={8} css={{
                            WebkitBorderTopLeftRadius: 0,
                            WebkitBorderTopRightRadius: 0,
                            maxHeight: 500,
                            overflow: "auto"
                        }}>
                            {isLoading ?
                                <p>hello</p>

                                :
                                expenses.map((exp) => (
                                    <>
                                        <Grid container item xs={4}>
                                            <Typography noWrap>{exp.name}</Typography>
                                        </Grid>
                                        <Grid container item xs={3}>
                                            <Typography>{exp.value.toFixed(3)}</Typography>
                                        </Grid>
                                        <Grid container item xs={3}>
                                            <Typography>{moment(exp.created_at).format("DD-M-YY")}</Typography>
                                        </Grid>
                                        <Grid container item xs={2}>
                                            <ApproveAction
                                                approveAction={() =>{
                                                    setProgressBar(true);
                                                    apiCall(accessToken)
                                                        .post(`/finances/expenses/delete/${exp.id}`)
                                                        .then((res) => {
                                                            setExpenses((prevState) => {
                                                                return prevState.filter((prev) => prev.id !== exp.id)
                                                            })
                                                        })
                                                        .catch((e) => {
                                                        })
                                                        .finally(() => setProgressBar(false))
                                                }}
                                                title={`Voules vous vraiment supprimer la depense "${exp.name}" a valeur de "${exp.value}  DT" ?`}
                                            >
                                                <DeleteForeverIcon
                                                    sx={{
                                                        cursor: "pointer",
                                                        "&:hover": {
                                                            color: t => t.palette.primary.main
                                                        }
                                                    }}
                                                />
                                            </ApproveAction>
                                        </Grid>
                                    </>
                                ))
                            }
                        </CustomPaper>
                    </Grid>
                </Grid>
            </Grid>
    )
}