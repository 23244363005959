import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';



export default function OrderCard({title,content}) {
    return (
        <Card sx={{ width: "100%" , color : "white", backgroundColor : (t) => t.palette.primary.main }}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }}  gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h5" component="div">
                    {content}
                </Typography>
            </CardContent>
        </Card>
    );
}