import React from "react";
import {Chip} from "@mui/material";
import SendToIntigo from "../../sendToIntigo";
import Typography from "@mui/material/Typography";


export default function DeliveryStatus({order}){
    let chip_color;
    let variant;
    switch (order.deliveryStatus)
    {
        // En Attente de livraison
        case 1 :
        case 2 :
            chip_color = "info";
            variant = "outlined"
            break;

        // Commande livrée
        case 6 :
            chip_color = "success";
            variant = "outlined";
            break;
        // Livraison En Cours
        case 10 :
            chip_color = "warning";
            variant = "outlined"
            break;

        //Annulée par vendeur
        case 16 :
        case 17 :
        case 14 :
            chip_color = "error";
            variant = "filled";
            break;
        case 15 :
            chip_color = "warning";
            variant = "filled";
            break;
        default :
            chip_color = "info";
            variant = "filled";
    }
    return(
        order.phase === null ?
            <SendToIntigo order={order}/>

            : order.phase === "dropOff" ?
                <Chip
                    sx={{
                        height : {
                            xs : 40,
                            sm : "auto"
                        }
                    }}
                    size="small"
                    label={<Typography textAlign="center" style={{whiteSpace: 'normal'}}>{order.deliveryStatusName}</Typography>}
                    color={chip_color}
                    variant={variant}
                />
            : <Chip
                sx={{
                    height : {
                        xs : 40,
                        sm : "auto"
                    }
                }}
                size="small"
                label={<Typography textAlign="center" style={{whiteSpace: 'normal'}}>{order.deliveryStatusName}</Typography>}
                color={chip_color}
                variant={variant}
            />
    )
}