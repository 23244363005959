import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Chip, Tooltip} from "@mui/material";
import Stack from "@mui/material/Stack";
import SelectedOrderContext from "../../../../../context/selectedOrderContext";
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import axios from "axios";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from '@mui/icons-material/Description';
import UserContext from "../../../../../context/userContext";
import StatsModal from "./Stats";

export default function TableActions({pagination,setPerPage}){
    const {selectedOrder,setSelectedOrder} = useContext(SelectedOrderContext);
    const {userRole} = useContext(UserContext);
    return(
        <Grid container item xs={12}>
            <Grid container item sm={6} xs={12}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <IconButton
                        sx={{
                            width : 35,
                            height :35,
                            color : "white",
                            backgroundColor : (t) => t.palette.success.main,
                            "&:hover" : {
                                backgroundColor : (t) => t.palette.success.light
                            }
                        }}
                        onClick={() => {
                            let ids = [];
                            selectedOrder.forEach((order) => {
                                ids.push(order.id)
                            });
                            window.open(`/orders/pdf/${ids.toString()}`,'_blank')
                        }}
                        disabled={selectedOrder.length === 0}
                        variant="filled"
                    >
                        <Tooltip title="Download Bon de commande">
                            <DownloadIcon sx={{fontSize : 25}}/>
                        </Tooltip>
                    </IconButton>
                    <IconButton
                        sx={{
                            width : 35,
                            height :35,
                            color : "white",
                            backgroundColor : (t) => t.palette.success.main,
                            "&:hover" : {
                                backgroundColor : (t) => t.palette.success.light
                            }
                        }}
                        onClick={() => {
                            let ids = [];
                            selectedOrder.forEach((order) => {
                                ids.push(order.id)
                            });
                            window.open(`/orders/facture/${ids.toString()}`,'_blank')
                        }}
                        disabled={selectedOrder.length === 0}
                        variant="filled"
                    >
                        <Tooltip title="Download Facture">
                            <DescriptionIcon sx={{fontSize : 25}}/>
                        </Tooltip>
                    </IconButton>
                    <IconButton
                        sx={{
                            width : 35,
                            height :35,
                            color : "white",
                            backgroundColor : (t) => t.palette.success.main,
                            "&:hover" : {
                                backgroundColor : (t) => t.palette.success.light
                            }
                        }}
                        variant="filled"
                        color="secondary"
                        disabled={selectedOrder.length === 0}
                        onClick={() =>{
                            const nids = [];

                                selectedOrder.forEach((order) => {
                                if(order.nid !== undefined) nids.push(order.nid)
                            });

                            axios.post(
                                "https://partner-app.intigo.tn/api/v1/externalapi/bordereau/",
                                { nids : nids},
                                {
                                    responseType : "blob"
                                }
                            )
                                .then(function (response) {
                                        let fileName = response.headers["content-disposition"].split("filename=")[1];
                                        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                                            window.navigator.msSaveOrOpenBlob(new Blob([response.data],
                                                    { type: 'application/pdf' }
                                                ),
                                                fileName
                                            );
                                        } else {
                                            const url = window.URL.createObjectURL(new Blob([response.data],
                                                { type: 'application/pdf' }));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download',
                                                response.headers["content-disposition"].split("filename=")[1]);
                                            document.body.appendChild(link);
                                            link.click();
                                        }
                                    }
                                )
                                .catch(e => {})
                        }}
                    >
                        <Tooltip title={"Download bordereau de livraison"}>
                            <PictureAsPdfIcon sx={{fontSize : 25}}/>
                        </Tooltip>
                    </IconButton>

                    <Stack>
                        <Typography>{selectedOrder.length} Commandes sélectionné</Typography>
                        {
                            selectedOrder.length > 0 &&
                            <Button
                                LinkComponent={Typography}
                                onClick={() => setSelectedOrder([])}
                            >
                                désélectionner tout
                            </Button>
                        }
                    </Stack>
                </Stack>
            </Grid>
            <Grid container item sm={6} xs={12}  justifyContent="flex-end">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="subtitle1">Par page :</Typography>
                    {[25, 50, 100, 150, 200].map((item) => (
                        <Chip disabled={pagination.perPage === item}  clickable onClick={() => setPerPage(item) } label={item} variant="filled" color="primary" size="small" />
                    ))}
                </Stack>
            </Grid>
        </Grid>
    )
}