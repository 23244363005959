import React, {useEffect, useState} from "react";
import UserContext from "../../context/userContext";
import {hashedKey} from "../../helpers/hashedKey";
import Loader from "../../component/common/loader";
import axios from "axios";
import {apiUrl} from "../../common";
import {headers} from "../../api/common";


export default function UserInfos(props) {
    const [user,setUser] = useState({isAuth : false , accessToken : null , user : null});
    const [loading , setLoading] = useState(true);
    useEffect(() => {
        const userData = localStorage.getItem(hashedKey('userData')) ? JSON.parse( localStorage.getItem(hashedKey('userData'))) : null;
        if(userData){
            axios.get(
                apiUrl + "manager/check_user",
                {
                    headers : headers(userData.accessToken)
                })
                .then((res) => {
                    setUser(prev => {
                        return{
                            isAuth : true,
                            accessToken : userData.accessToken,
                            user: res.data.user,
                        }
                    });
                    return res
                })
                .then((res) => {
                    const localStorageItem = {
                        isAuth : true,
                        accessToken : userData.accessToken,
                        user: res.data.user,
                    }
                    localStorage.setItem(hashedKey('userData'), JSON.stringify(localStorageItem));
                    setLoading(false);

                }).catch((e) => {
                setUser(prev => {
                    return{
                        isAuth : false,
                        accessToken : userData.accessToken,
                        user: ""
                    }
                });
                setLoading(false);

            });


        }else {
            setLoading(false);
        }

    },[]);

    function logoutUser () {
        setUser(prev => {
            return{
                isAuth : false,
                accessToken : "",
                user: ""
            }
        });
    }
    return(
        !loading ?
        <UserContext.Provider value={{isAuth :user.isAuth,accessToken: user.accessToken, userInfos : user.user,setUser,userRole : user.isAuth ? user.user.role : null,logoutUser : logoutUser}}>
            {props.children}
        </UserContext.Provider>
            :
            <Loader/>
    )
}