import React, {createContext, useContext, useEffect, useState} from "react";
import UserContext from "../../context/userContext";
import AdminDashboard from "./adminDashboard";
import MagDashboard from "./magDashboard";
import Box from "@mui/material/Box";
import {Tab, Tabs} from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Finance from "./finance";
import apiCall from "../../api/axios_config";
import moment from "moment";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Dashboard(){

    const {userRole,userInfos} = useContext(UserContext);
    const [value, setValue] = React.useState(0);
    const [orderOftheMonth, setOrderOftheMonth] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {accessToken} = useContext(UserContext);
    useEffect(() => {
        apiCall(accessToken)
            .get(`/orders/orders_of_months/${moment().format('DD-MM-Y')}`)
            .then((res) => setOrderOftheMonth(res.data))
            .catch(e => {})
            .finally(() => setIsLoading(false));

        apiCall(accessToken)
            .get('/orders/all_sync').then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return(
        <DashboardContext.Provider value={{orderOftheMonth,setOrderOftheMonth,isLoading,setIsLoading}}>
            {userRole === "admin" ?
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Main" {...a11yProps(0)} />
                            <Tab disabled={isLoading} label="Finance" {...a11yProps(1)} />
                            <Tab label="Amina Ayach" {...a11yProps(2)} />

                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <AdminDashboard/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Finance/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <MagDashboard store={userInfos.store ? userInfos.store.id : 1}/>
                    </TabPanel>
                </Box>

                : userRole === "magasinier" ?
                    <MagDashboard store={userInfos.store ? userInfos.store.id : 1}/>

                    : <p>No role Here</p>

            }
        </DashboardContext.Provider>
        )
}

export const DashboardContext = createContext(null);
