import React, {useContext} from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import AlertsContext from "../../context/alertsContext";
import Typography from "@mui/material/Typography";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function SnackBar({vertical = "top", horizontal = "center"}) {
    const {snackBar,setSnackBar} = useContext(AlertsContext);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBar(false);
    };
    return(
        <Stack spacing={2} sx={{ width: '100%' }}>

            <Snackbar
                anchorOrigin={{vertical,horizontal}}
                open={snackBar.status}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={snackBar.severity} sx={{ width: '100%' }}>
                    <Typography fontWeight={500}>{snackBar.message}</Typography>
                </Alert>
            </Snackbar>
        </Stack>
    )
}