

export function sousTotal(items){
    let result = 0;
    items.forEach((item) => {
        result += (parseInt(item.quantity) * parseInt(item.product.price))
    });
    return result;
}

export function calculateDiscount(order){
    return (sousTotal(order.items) / 100) * order.discount
}
export function calculateTotal(items,discount,deliveryPrice){
    const sousT = sousTotal(items);
    const disc = (sousT / 100) * discount;
    if(discount === 0) {
        return  sousT + parseInt(deliveryPrice);
    } else {
        return  (sousT - disc) + deliveryPrice
    }
}

export function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}